const INITIAL_STATE = {
    newsList : [],
    news:{}
}

export default function news(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_NEWS_LIST":
        return {
            ...state,
            newsList : action.payload 
        };
      case "GET_NEWS":
        return {
            ...state,
            news : action.payload
        };
      default:
        return state;
    }
  }