import React, { useState, useEffect } from 'react';
import News from '../../Components/News/News'

import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { PostNews } from "../../store/actions/news";

export default function AddNews() {
    const history = useHistory();
    let auth  = useSelector(state => state.auth);
    const [disabledBtn, setdisabledBtn] = useState(true);

    const [form, setForm] = useState({
        inputTitle: "",
        inputDescText: "",
        inputVideoLink: "",
        inputCreatedBy: "",
        inputShowIntro : true,
        inputActive : true,
    });

    const [formErrors, setFormError] = useState({
        inputTitleErr: null,
        inputDescTextErr: null,
        inputVideoLinkErr: null,
    });

    useEffect(() => {}, [form]);
    useEffect(()=>{
        if(auth === null){
            history.push('/');
        }
    },[auth, history])

    const handelFormChange = (e) => {
      if (e.target.name === "inputTitle") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputTitle: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputTitleErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : null,
          });
      } 
      else if (e.target.name === "inputDescText") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputDescText: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputDescTextErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : null,
          });
      } 
      else if (e.target.name === "inputVideoLink") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputVideoLink: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputVideoLinkErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : null,
          });
      } 
      else if (e.target.name === "inputShowIntro") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputShowIntro: e.target.checked,
        });
      }
      else if (e.target.name === "inputActive") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputActive: e.target.checked,
        });
      }
    };
    

    const validationForm = ()=>{
        setFormError({
        ...formErrors,
        inputTitleErr: form.inputTitle === "" ?"* هذا الحقل مطلوب" :null,
        inputDescTextErr:  form.inputDescText === ""? "* هذا الحقل مطلوب":null,
        inputVideoLinkErr:  form.inputVideoLink === ""? "* هذا الحقل مطلوب":null
        });
    }
  
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setdisabledBtn(true)
        if(form.inputTitle === "" || form.inputDescText === "" || form.inputVideoLink === ""){
        validationForm()
        return
        }
        console.log(" form ==>>",form)
        try{
            await PostNews({
              Title : form.inputTitle,
              Description: form.inputDescText,
              VideoLink: form.inputVideoLink,
              CreatedBy : auth.Username,
              ShowIntro : form.inputShowIntro,
              Active : form.inputActive,
            },auth.token)
            setForm({
                inputTitle: "",
                inputDescText: "",
                inputVideoLink: "",
                inputCreatedBy: "",
                inputShowIntro : true,
                inputActive : true,
            })
            setFormError({
                inputTitleErr: null,
                inputDescTextErr: null,
                inputVideoLinkErr: null,
            })
            await toast.success("تم إضافة خبر جديد");
            history.push('/news');
        } catch (err) {
            console.log("err ==> ",err)
            if(err.response.data === "UN_AUTH"){
                toast.error("يجب تسجيل الدخول");
            }
        }
    };

  return (
    <>
        {auth !== null && <section>
            <ToastContainer />
            <h1>
                إضافة خبر جديد
            </h1>
            <News
                form={form}
                formErrors={formErrors}
                handelFormChange={handelFormChange}
                handleFormSubmit={handleFormSubmit}
                disabledBtn={disabledBtn}
                action={'addNews'}
            />
        </section>}
    </>   
  )
}

