import React, { useState , useEffect} from 'react'
import "./Home.scss";

import Login from "../../Components/Login/Login";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { adminLogin } from "../../store/actions/auth";

export default function Home() {
    const history = useHistory();
    const dispatch = useDispatch();
    let auth  = useSelector(state => state.auth);
    const [disabledBtn, setdisabledBtn] = useState(true);
    const [form, setForm] = useState({
        inputUser: "",
        inputPassword: "",
    });
    const [formErrors, setFormError] = useState({
        inputUserErr: null,
        inputPasswordErr: null,
    });

    useEffect(() => {}, [form]);
    useEffect(()=>{
        if(auth !== null){
        history.push('/users');
        }else{
        history.push('/');
        }
    },[auth, history])

    const handleEnglishKeyPress = (event) => {
        if (
            !(
                /^[a-zA-Z0-9!@#$%^&*]$/.test(event.key) || 
                event.key === 'Backspace' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    };

    const handelFormChange = (e) => {
        if (e.target.name === "inputUser") {
            setdisabledBtn(false)
            setForm({
                ...form,
                inputUser: e.target.value,
            });
            setFormError({
                ...formErrors,
                inputUserErr:
                    e.target.value.length === 0
                        ? "* هذا الحقل مطلوب"
                        : null,
            });
        } else if (e.target.name === "inputPassword") {
            setdisabledBtn(false)
            setForm({
                ...form,
                inputPassword: e.target.value,
            });
            setFormError({
                ...formErrors,
                inputPasswordErr:
                    e.target.value.length === 0
                        ? "* هذا الحقل مطلوب"
                        : null,
            });
        }
    };

    const handleFormValidation = (e)=>{
    setFormError({
        ...formErrors,
        inputUserErr: form.inputUser === "" ?"* هذا الحقل مطلوب" :null,
        inputPasswordErr:  form.inputPassword === ""? "* هذا الحقل مطلوب":null,
    });
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setdisabledBtn(true)
        if(form.inputUser === "" ||form.inputPassword === "" ){
            handleFormValidation()
            return
        }
        try{
        let res = await adminLogin({
            Username: form.inputUser,
            Password: form.inputPassword
        })
        if(res.data){
            setForm({
                inputUser: "",
                inputPassword: "",
            })
            setFormError({
                inputUserErr: null,
                inputPasswordErr: null,
            })
            await toast.success("تم تسجيل الدخول بنجاح");
            window.localStorage.setItem("auth",JSON.stringify(res.data))
            dispatch({
                type: "LOGGED_IN_ADMIN",
                payload: res.data,
            });
            history.push('/users');
            }
        } catch (err) {
            if(err.response.data === "UN_AUTH"){
                toast.error("اسم المستخدم أو كلمة المرور غير صحيحة");
            }
        }
    };

    return(
        <>
            {auth === null && <>
                <ToastContainer />
                <section className="position-absolute top-50 start-50 translate-middle">
                    <Login 
                        handelFormChange={handelFormChange}
                        handleFormSubmit={handleFormSubmit}
                        form={form}
                        formErrors={formErrors}
                        handleEnglishKeyPress={handleEnglishKeyPress}
                        disabledBtn={disabledBtn}
                    />
                </section>
            </>}
        </>
    )
}
