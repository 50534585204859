const INITIAL_STATE = {
    stagesList : {
      rows:[],
      totalCount:0
    }
}

export default function stages(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_STAGES_LIST":
        return {
            ...state,
            stagesList : action.payload 
        };
      default:
        return state;
    }
  }