const INITIAL_STATE = {
    coursesList : [],
    course:{}
}

export default function courses(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_COURSES_LIST":
        return {
            ...state,
            usersList : action.payload 
        };
      case "GET_COURSE":
        return {
            ...state,
            user : action.payload
        };
      default:
        return state;
    }
  }