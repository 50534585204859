import { axiosInstance } from "../../network/axios";

export const getLevels =  (token,paginationModel) => async (dispatch) => {
    await axiosInstance.get(`/admin/levels?page=${paginationModel.page}&limit=${paginationModel.pageSize}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_LEVELS_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}


export const getProgrammeLevels =  (programme,token,paginationModel) => async (dispatch) => {
    await axiosInstance.get(`/admin/programmelevels/${programme}&page=${paginationModel.page}&limit=${paginationModel.pageSize}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_LEVELS_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const deleteLevel =  async(id,token) => {
    await axiosInstance.delete(`/admin/levels/${id}`, {
        headers: {
        authorization: token,
        },
    })
}

export const AddLevels =  async (level , token) =>{
    await axiosInstance.post("/admin/levels",level ,{
        headers: {
        authorization: token,
        },
    });
}

export const EditLevels =  async (id , level , token) =>{
    await axiosInstance.patch(`/admin/levels/${id}`,level ,{
        headers: {
        authorization: token,
        },
    });
}

/* For Stages */
export const getProgrammeLevelsById =  (programme,token) => async (dispatch) => {
    await axiosInstance.get(`/admin/programmelevelsid/${programme}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_PROGRAMME_LEVELS_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}