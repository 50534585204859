const INITIAL_STATE = {
    adminsList : [],
    admin:{}
}

export default function admins(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_ADMINS_LIST":
        return {
            ...state,
            usersList : action.payload 
        };
      case "GET_ADMIN":
        return {
            ...state,
            user : action.payload
        };
      default:
        return state;
    }
  }