import React from 'react'
import './Levels.scss'
import { useMemo, useState } from "react";
import {Link, useHistory} from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Select from 'react-select'

import { getLevels } from "../../store/actions/levels";
import { getProgrammeLevels } from "../../store/actions/levels";
import { getProgrammesLevel } from "../../store/actions/programmes";
import DataGridComponent from "../../Components/DataGrid/DataGridComponent";
import moment from 'moment'
import ActionButtons from "../../Components/ActionButtons/ActionButtons";
import ConfirmModal from "../../Components/Modal/ConfirmModal";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Levels() {
    const history = useHistory();
    const dispatch = useDispatch();
    let auth  = useSelector(state => state.auth);
    let programmesLevelList = useSelector((state) => state.programmes.programmesLevelList);
    let levelsList = useSelector((state) => state.levels.levelsList);
    const [loading, setLoading] = useState(false);
    const[rowId,setRowId] = useState(null)
    const[selectProgramme,setSelectProgramme] = useState()
    const [showConfirmModal,setShowConfirmModal] = useState({
        open:false,
        id:null,
        type:''
      })
      const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
      });

    const ProgrammesOptions = programmesLevelList.map((programme)=>({
      value: programme.ID, label: programme.Title 
    }))

    const columns= useMemo(()=>[
      {
        field: 'id',
        headerName: 'تعريف',
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'ProgrammeName',
        headerName: 'البرنامج',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'Title',
        headerName: 'المستوى',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'Active',
        headerName: 'التفاعل',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        type: 'boolean'
      },
      {
        field: 'StartDate',
        headerName: 'تاريخ البدء',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => moment(params.row.StartDate).format('YYYY-MM-DD HH:mm:SS')
      },
      {
        field: 'EndDate',
        headerName: 'تاريخ الانتهاء',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => moment(params.row.EndDate).format('YYYY-MM-DD HH:mm:SS')
      },
      {
        field: 'Actions',
        headerName: '',
        flex: 2,
        type:'actions',
        renderCell: (params) => <ActionButtons {...{params,rowId,setRowId,handelConfirmModal,toast,where:"levels"}}/>,
      },
    ]);

    const rows = levelsList.rows.map((level)=>
      ({id:level.ID,
        ProgrammeName:level.programme.Title,
        Title:level.Title,
        Active:level.Active,
        StartDate:level.StartDate,
        EndDate:level.EndDate,
      })
    );

    useEffect(()=>{
      handleGetProgrammes()
      handelGetLevels()
    },[])

    useEffect(()=>{
      handleSelectProgrammeChange(selectProgramme)
    },[rowId,paginationModel,selectProgramme])

    const handelGetLevels  = async()=>{
      if(auth === null){
        history.push('/');
      }else{
        setLoading(true)
        dispatch(getLevels(auth.token,paginationModel));
        setLoading(false)
      }
    }

    const handleGetProgrammes = ()=>{
      if(auth === null){
        history.push('/');
      }else{
        if(programmesLevelList.length === 0){
          setLoading(true)
          dispatch(getProgrammesLevel(auth.token));
          setLoading(false)
        }
      }
    }

    const handleSelectProgrammeChange =async(programme)=>{
      setSelectProgramme(programme);
      if(programme?.value){
        getSelectProgrammeLevels(programme.value)
      }else{
        handelGetLevels()
      }
    }

    const getSelectProgrammeLevels = async(programme)=>{
      setLoading(true)
      dispatch(getProgrammeLevels(programme,auth.token,paginationModel));
      setLoading(false)
    }

    const handleClose = async() => {
        setShowConfirmModal({
          ...showConfirmModal,
          open:false
        });
        if(selectProgramme){
          getSelectProgrammeLevels(selectProgramme.value)
        }else{
          handelGetLevels()
        }
      }
  
      const handelConfirmModal = (pramsId) =>{
        setShowConfirmModal({
          open:true,
          id:pramsId,
          type:"level"
        })
      }
      
    return(
      <>
        { auth !== null && <section className="position-relative">
            <ToastContainer />
            <ConfirmModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal} auth={auth} handleClose={handleClose}/>
          <h1>المستويات</h1>
          <div className="d-flex justify-content-start mb-3">
            <Link to={'/level'} className="py-2 px-3 border rounded-pill text-decoration-none fs-5 text-secondary">
              <span className="ms-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                </svg>
              </span>
              إضافة مستوى جديد
            </Link>
          </div>
          <div className="d-flex justify-content-start mb-3">
            <div className="mt-3 position-relative">
            <label htmlFor="inputAcademic" className="form-label secondary-color fs-6">البرنامج</label>
            <Select 
              className="selectCountry" 
              name="inputAcademic" 
              id="inputAcademic" 
              options={[{value: "", label: "الكل"},...ProgrammesOptions]} 
              value={ProgrammesOptions.find(val=>val.value === selectProgramme)}  
              onChange={handleSelectProgrammeChange} 
              placeholder="أختر البرنامج"
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(76,125,120,.25)',
                  primary: 'rgba(76,125,120,.25)',
                },
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  boxShadow: state.isFocused ?'0 0 0 0.25rem rgba(76,125,120,.25)':'',
                  borderColor: state.isFocused?'#4c7d78': "hsl(0, 0%, 80%)",
                }),
                placeholder: (styles) => ({ ...styles, color:'#4c7d78' }),
                option: (styles) => ({ ...styles, color:'#4c7d78' }),
                singleValue: (styles, { data }) => ({ ...styles, color:'#4c7d78'})
              }}
            />
            </div>
          </div>
          <DataGridComponent 
          rows={rows} 
          columns={columns} 
          setRowId={setRowId} 
          loading={loading}
          rowCount={levelsList.totalCount}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          />
        </section>}
      </>
    )
}
