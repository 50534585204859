import { axiosInstance } from "../../network/axios";

export const getProgrammes =  (token,paginationModel) => async (dispatch) => {
    await axiosInstance.get(`/admin/programmes?page=${paginationModel.page}&limit=${paginationModel.pageSize}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_PROGRAMMES_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const getProgrammesLevel =  (token) => async (dispatch) => {
    await axiosInstance.get("/admin/programmeslevels", {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_PROGRAMMES_LEVEL_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const getProgrammesStage =  (token) => async (dispatch) => {
    await axiosInstance.get("/admin/programmesstages", {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_PROGRAMMES_STAGE_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const deleteProgramme =  async(id,token) => {
    await axiosInstance.delete(`/admin/programmes/${id}`, {
        headers: {
        authorization: token,
        },
    })
}
