import React from 'react'
import './Accept.scss'
import { useMemo, useState } from "react";
import { useHistory} from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Select from 'react-select'

import { getProgrammes } from "../../store/actions/programmes";
import { getAccepts } from "../../store/actions/accepts";
import { getProgrammeAccepts } from "../../store/actions/accepts";
import DataGridComponent from "../../Components/DataGrid/DataGridComponent";
import moment from 'moment'
import ActionButtons from "../../Components/ActionButtons/ActionButtons";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Accept() {
    const history = useHistory();
    const dispatch = useDispatch();
    let auth  = useSelector(state => state.auth);
    let programmesList = useSelector((state) => state.programmes.programmesList);
    let acceptsList = useSelector((state) => state.accepts.acceptsList);
    const [loading, setLoading] = useState(false);
    const[rowId,setRowId] = useState(null)
    const[selectProgramme,setSelectProgramme] = useState()
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: 5,
    });

    const ProgrammesOptions = programmesList.rows.map((programme)=>({
      value: programme.ID, label: programme.Title 
    }))

    const columns= useMemo(()=>[
      {
        field: 'id',
        headerName: 'تعريف',
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'Username',
        headerName: 'اسم المشترك',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'Programme',
        headerName: 'اسم البرنامج',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'Status',
        headerName: 'التصريح',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        type: 'singleSelect',
        valueOptions:['approved','pending','reject']
      },
      {
        field: 'Reject',
        headerName: 'سبب الرفض',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        // editable:  params => {
        //   console.log("Hereeeeeeeeee ", params.row.Status)
        //   return params.row.Status === 'reject'
        // }
      },
      {
        field: 'CreatedAt',
        headerName: 'تاريخ الاشتراك',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => moment(params.row.CreatedAt).format('YYYY-MM-DD HH:mm:SS')
      },
      {
        field: 'Actions',
        headerName: '',
        flex: 2,
        type:'actions',
        renderCell: (params) => <ActionButtons {...{params,rowId,setRowId,toast,where:"accept"}}/>,
      },
    ]);

    const rows = acceptsList.rows.map((userprogramme)=>
      ({id:userprogramme.ID,
        Username:userprogramme.user.Name,
        Programme:userprogramme.programme.Title,
        Status:userprogramme.Status,
        Reject:userprogramme.Reject,
        CreatedAt:userprogramme.CreatedAt,
      })
    );

    useEffect(()=>{
      handleGetProgrammes()
      handleGetAccepts()
    },[])

    useEffect(()=>{
      handleSelectProgrammeChange(selectProgramme)
    },[rowId,paginationModel,selectProgramme])

    const handleGetProgrammes = ()=>{
      if(auth === null){
        history.push('/');
      }else{
        setLoading(true)
        dispatch(getProgrammes(auth.token,paginationModel));
        setLoading(false)
      }
    }

    const handleGetAccepts = async()=>{
      if(auth === null){
        history.push('/');
      }else{
        setLoading(true)
        dispatch(getAccepts(auth.token,paginationModel));
        setLoading(false)
      }
    }

    const handleSelectProgrammeChange =async(programme)=>{
      setSelectProgramme(programme);
      if(programme?.value){
        getSelectProgrammeAccepts(programme.value)
      }else{
        handleGetAccepts()
      }
    }

    const getSelectProgrammeAccepts = async(programme)=>{
      setLoading(true)
      dispatch(getProgrammeAccepts(auth.token,programme,paginationModel));
      setLoading(false)
    }
      
    return(
      <>
        { auth !== null && <section className="position-relative">
          <ToastContainer />
          <h1>القبول فى البرامج</h1>
          <div className="d-flex justify-content-start mb-3">
            
          <div className="mt-3 position-relative">
            <label htmlFor="inputAcademic" className="form-label secondary-color fs-6">البرنامج</label>
            <Select 
              className="selectCountry" 
              name="inputAcademic" 
              id="inputAcademic" 
              options={[{value: "", label: "الكل"},...ProgrammesOptions]} 
              value={ProgrammesOptions.find(val=>val.value === selectProgramme)}  
              onChange={handleSelectProgrammeChange} 
              placeholder="أختر البرنامج"
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(76,125,120,.25)',
                  primary: 'rgba(76,125,120,.25)',
                },
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  boxShadow: state.isFocused ?'0 0 0 0.25rem rgba(76,125,120,.25)':'',
                  borderColor: state.isFocused?'#4c7d78': "hsl(0, 0%, 80%)",
                }),
                placeholder: (styles) => ({ ...styles, color:'#4c7d78' }),
                option: (styles) => ({ ...styles, color:'#4c7d78' }),
                singleValue: (styles, { data }) => ({ ...styles, color:'#4c7d78'})
              }}
            />
        </div>

          </div>
          <DataGridComponent 
            rows={rows} 
            columns={columns} 
            setRowId={setRowId} 
            loading={loading}
            rowCount={acceptsList.totalCount}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </section>}
      </>
    )
}
