const INITIAL_STATE = {
  acceptsList : {
    rows:[],
    totalCount:0
  },
}

export default function accepts(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_ACCCEPTS_LIST":
        return {
            ...state,
            acceptsList : action.payload
        };
      default:
        return state;
    }
  }