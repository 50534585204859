import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export default function ShowNavbar({children}) {

    const location = useLocation();
    const [ShowNavbar,setShowNavbar] = useState(false);

    useEffect(()=>{
        if(location.pathname === '/'){
            setShowNavbar(false)
        }else{
            setShowNavbar(true)
        }
    },[location])

  return (
    <>{ShowNavbar && children}</>
  )
}
