import React from 'react'

export default function Header() {
  return (
    <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      {/* <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">اسم الشركة</a> */}
      <button className="navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="عرض/إخفاء لوحة التنقل">
        <span className="navbar-toggler-icon"></span>
      </button>
      {/* <div className="navbar-nav">
        <div className="nav-item text-nowrap">
          <a className="nav-link px-3" href="#">تسجيل الخروج</a>
        </div>
      </div> */}
    </header>
  )
}
