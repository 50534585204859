import React from 'react'
import "./ConfirmModal.scss"

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { deleteUser } from "../../store/actions/users";
import { deleteNews } from "../../store/actions/news";
import { deleteProgramme } from "../../store/actions/programmes";
import { deleteLevel } from "../../store/actions/levels";
import { deleteStage } from "../../store/actions/stages";

export default function ConfirmModal({showConfirmModal ,setShowConfirmModal, auth ,handleClose}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '0 solid #000',
        borderRadius :'10px',
        boxShadow: 24,
        px: 4,
        py: 3,
    };

    const handleDelete = async(id,type) =>{
        try{
            if(type === 'user'){
                await deleteUser(id,auth.token);
            }else if(type === 'news'){
                await deleteNews(id,auth.token);
            }else if(type === 'programme'){
                await deleteProgramme(id,auth.token);
            }else if(type === 'level'){
                await deleteLevel(id,auth.token);
            }else if(type === 'stage'){
                await deleteStage(id,auth.token);
            }
            handleClose()
        }catch(err){
            console.log(err.response.data)
        }
    }

  return (
    <section className='confirmModal' id='confirmModal'>
        <Modal
            open={showConfirmModal.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h5" sx={{ mt: 2}}>
                    {`هل انت متأكد من مسح هذا ${showConfirmModal.type === "user"? "المستخدم"
                        : showConfirmModal.type === "news"?"الخبر"
                        : showConfirmModal.type === "programme"?"البرنامج"
                        : showConfirmModal.type === "level"?"المستوى"
                        : showConfirmModal.type === "stage"?"المرحلة"
                        : "العنصر"}
                    `}
                </Typography>
                <div className='d-flex justify-content-end mt-3'>
                    <Button onClick={handleClose} className='modal-button-close ms-3 fs-5'>لا</Button>
                    <Button onClick={()=>handleDelete(showConfirmModal.id,showConfirmModal.type)} className='modal-button-delete ms-3 fs-5'>نعم</Button>
                </div>
            </Box>
        </Modal>
    </section>
  )
}
