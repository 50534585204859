import { combineReducers } from "redux";
import auth from "./auth";
import users from "./users";
import programmes from "./programmes";
import levels from "./levels";
import stages from "./stages";
import books from "./books";
import courses from "./courses";
import admins from "./admins";
import news from "./news";
import accepts from "./accepts";

export default combineReducers({
    auth:auth,
    users:users,
    programmes:programmes,
    levels:levels,
    stages:stages,
    books:books,
    courses:courses,
    admins:admins,
    news:news,
    accepts:accepts
});