import React, { useEffect, useState } from 'react'
import {Box, CircularProgress, Fab, IconButton, Tooltip} from '@mui/material'
import {Check, Delete, Edit, Preview, Save} from '@mui/icons-material';
import MailLockIcon from '@mui/icons-material/MailLock';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { useSelector } from "react-redux";
import { axiosInstance } from "../../network/axios";
import { useHistory } from "react-router-dom";


export default function ActionButtons({params,rowId,setRowId,handelConfirmModal,toast,where}) {
    let auth  = useSelector(state => state.auth);
    const history = useHistory();
    const [loading,setLoading] = useState(false)
    const [success,setSuccess] = useState(false)

    const handleSubmit = async()=>{
        setLoading(true)
        setTimeout(async()=>{
            try{
                let res
                if(where === 'users'){
                    res = await axiosInstance.patch(`/admin/usersactions/${params.row.id}`,{
                        Approval:params.row.Approval,
                        Active: params.row.Active
                    },{
                        headers: {
                        authorization: auth.token,
                        },
                    });
                }else if( where === 'programmes'){
                    res = await axiosInstance.patch(`/admin/programmesactions/${params.row.id}`,{
                        MainProgramme:params.row.MainProgramme,
                        ShowFront:params.row.ShowFront,
                        Active: params.row.Active
                    },{
                        headers: {
                        authorization: auth.token,
                        },
                    });
                }else if( where === 'accept'){
                    res = await axiosInstance.patch(`/admin/usersprogrammes/${params.row.id}`,{
                        Status: params.row.Status,
                        Reject: params.row.Reject
                    },{
                        headers: {
                        authorization: auth.token,
                        },
                    });
                }else if( where === 'levels'){
                    res = await axiosInstance.patch(`/admin/levelsactions/${params.row.id}`,{
                        Active: params.row.Active
                    },{
                        headers: {
                        authorization: auth.token,
                        },
                    });
                }else if( where === 'stages'){
                    res = await axiosInstance.patch(`/admin/stagesactions/${params.row.id}`,{
                        Active: params.row.Active
                    },{
                        headers: {
                        authorization: auth.token,
                        },
                    });
                }
                if(res.data){
                    setSuccess(true)
                    setRowId(null)
                }
            }catch(err){}            
            setLoading(false)
        },1500)
    }

    const handleSendActiveEmail= async()=>{
        try{
            let res = await axiosInstance.post(`/admin/reactivatedemail`,{
                Email : params.row.Email },{headers: {authorization: auth.token,}})
            if(res.data){
                await toast.success("تم ارسال الايميل بنجاح");
            }
        }catch{}
    }

    const handleSendChangePassEmail= async()=>{
        try{
            let res = await axiosInstance.post("/admin/forgetpassword", {
                Email : params.row.Email
            },{headers: {authorization: auth.token,}})
            if(res.data){
                await toast.success("تم ارسال الايميل بنجاح");
            }
        }catch{}
    }

    useEffect(()=>{
        if(rowId === params.id && success){
            setSuccess(false)
        }
    },[rowId])

  return (
    <Box sx={{position:'relative'}}>
        {(where === 'users' || where === 'programmes' || where === 'levels' || where === 'stages') &&
        <Tooltip title='View'>
            <IconButton onClick={()=>{}}>
                <Preview />
            </IconButton>
        </Tooltip>
        }
        {(where === 'users' || where === 'programmes' || where === 'levels' || where === 'stages') &&
        <Tooltip title='Edit'>
            <IconButton onClick={()=>{
                if(where === 'users'){
                    history.push(`/user/${params.id}`)
                }else if(where === 'programmes'){
                    history.push(`/programme/${params.id}`)
                }else if(where === 'levels'){
                    history.push(`/level/${params.id}`)
                }else if(where === 'stages'){
                    history.push(`/stage/${params.id}`)
                }
                }}>
                <Edit />
            </IconButton>
        </Tooltip>
        }
        {(where === 'users' || where === 'programmes' || where === 'levels' || where === 'stages') &&
        <Tooltip title='Delete'>
            <IconButton onClick={()=>handelConfirmModal(params.id)}>
                <Delete />
            </IconButton>
        </Tooltip>
        }
        {where === 'users' && (
            <Tooltip title='Send ActiveEmail'>
                <IconButton onClick={() => handleSendActiveEmail()}>
                    <MarkEmailReadIcon />
                </IconButton>
            </Tooltip>
        )}
        {where === 'users' && (
            <Tooltip title='Send Change Password'>
                <IconButton onClick={() => handleSendChangePassEmail()}>
                    <MailLockIcon />
                </IconButton>
            </Tooltip>
        )}
        <Tooltip title='Save'>
            {success ? (
                <Fab
                    color='primary'
                    sx={{
                        width:40,
                        height:40,
                        bgcolor:'green',
                        '&hover':{bgcolor:'green'}
                    }}
                >
                    <Check/>
                </Fab>
            ):(
                <Fab
                    color='primary'
                    sx={{
                        width:40,
                        height:40,
                    }}
                    disabled={params.id !== rowId || loading}
                    onClick={handleSubmit}
                >
                    <Save/>
                </Fab>
            )}
            {loading && (
                <CircularProgress
                    size={52}
                    sx={{
                        color:"green",
                        position:'absolute',
                        top: -6,
                        left: -6,
                        zIndex:1
                    }}
                />
            )}
        </Tooltip>

    </Box>
  )
}
