import "./Users.scss";
import { useMemo, useState } from "react";
import {Link, useHistory} from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { getUsers } from "../../store/actions/users";
import ConfirmModal from "../../Components/Modal/ConfirmModal";
import DataGridComponent from "../../Components/DataGrid/DataGridComponent";
import moment from 'moment'
import ActionButtons from "../../Components/ActionButtons/ActionButtons";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Users() {
    const history = useHistory();
    const dispatch = useDispatch();
    let auth  = useSelector(state => state.auth);
    let usersList = useSelector((state) => state.users.usersList);
    const [loading, setLoading] = useState(false);
    const[rowId,setRowId] = useState(null)
    const [showConfirmModal,setShowConfirmModal] = useState({
      open:false,
      id:null,
      type:''
    })

    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: 5,
    });

    const columns= useMemo(()=>[
      {
        field: 'id',
        headerName: 'تعريف',
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'Name',
        headerName: 'الاسم ثلاثي',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'Approval',
        headerName: 'التصريح',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        type: 'boolean'
      },
      {
        field: 'Active',
        headerName: 'التفاعل',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        type: 'boolean'
      },
      {
        field: 'CreatedAt',
        headerName: 'تاريخ الانشاء',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => moment(params.row.CreatedAt).format('YYYY-MM-DD HH:mm:SS')
      },
      {
        field: 'Actions',
        headerName: '',
        flex: 2,
        type:'actions',
        renderCell: (params) => <ActionButtons {...{params,rowId,setRowId,handelConfirmModal,toast,where:"users"}}/>,
      },
    ]);

    const rows = usersList.rows.map((user)=>
      ({id:user.ID,
        Name:user.Name,
        Approval:user.Approval,
        Active:user.Active,
        CreatedAt:user.CreatedAt,
        Email:user.Email
      })
    );

    useEffect(()=>{
      handleGetUsers()
    },[rowId,paginationModel])

    const handleGetUsers = ()=>{
      if(auth === null){
        history.push('/');
      }else{
        setLoading(true)
        dispatch(getUsers(auth.token,paginationModel));
        setLoading(false)
      }
    }

    const handleClose = async() => {
      setShowConfirmModal({
        ...showConfirmModal,
        open:false
      });
      handleGetUsers()
    }

    const handelConfirmModal = (pramsId) =>{
      setShowConfirmModal({
        open:true,
        id:pramsId,
        type:"user"
      })
    }
      
    return(
      <>
        { auth !== null && <section className="position-relative">
          <ToastContainer />
          <ConfirmModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal} auth={auth} handleClose={handleClose}/>
          <h1>المستخدمين</h1>
          <div className="d-flex justify-content-start mb-3">
            <Link to={'/user'} className="py-2 px-3 border rounded-pill text-decoration-none fs-5 text-secondary">
              <span className="ms-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                </svg>
              </span>
              إضافة مستخدم جديد
            </Link>
          </div>
          <DataGridComponent 
          rows={rows} 
          columns={columns} 
          setRowId={setRowId}
          loading={loading}
          rowCount={usersList.totalCount}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          />
        </section>}
      </>
    )
}
