import "./News.scss";
import { useState } from "react";
import {Link, useHistory} from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { getNewsList } from "../../store/actions/news";
import ConfirmModal from "../../Components/Modal/ConfirmModal";
import DataGridComponent from "../../Components/DataGrid/DataGridComponent";

export default function News() {
    const history = useHistory();
    const dispatch = useDispatch();
    let auth  = useSelector(state => state.auth);
    let newsList = useSelector((state) => state.news.newsList);

    const [showConfirmModal,setShowConfirmModal] = useState({
      open:false,
      id:null,
      type:''
    })

    const columns= [
      {
        field: 'id',
        headerName: 'تعريف',
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        // editable: true,
      },
      {
        field: 'title',
        headerName: 'عنوان الخبر',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        // editable: true,
      },
      {
        field: 'createdBy',
        headerName: 'كاتب الخبر',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        // editable: true,
      },
      {
        field: 'show',
        headerName: 'إظهار الخبر',
        // valueGetter: (value) => `${value} سنوات`,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        // editable: true,
      },
      {
        field: 'active',
        headerName: 'تفعيل الخبر',
        // valueGetter: (value) => `${value} سنوات`,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        // editable: true,
        renderCell: (params) =>{
          // console.log("params => ",params)

        }
      },
      {
        field: 'actions',
        headerName: '',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        // editable: true,
        renderCell: (params) => 
        <>
          <Link to={`/news/${params.id}`} className="ms-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
            </svg>
          </Link>
          <button className="border-0 bg-transparent" onClick={()=>handelConfirmModal(params.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
            </svg>
          </button>
        </>
        ,
      },
    ];
    const rows = newsList.map((news)=>
      ({id:news.ID,
        title:news.Title,
        createdBy:news.CreatedBy,
        show:news.ShowIntro,
        active:news.Active
      })
    );

    useEffect(()=>{
      handlegetNewsList()
    },[])

    const handlegetNewsList = ()=>{
      if(auth === null){
        history.push('/');
      }else{
        dispatch(getNewsList(auth.token));
      }
    }

    const handleClose = async() => {
      setShowConfirmModal({
        ...showConfirmModal,
        open:false
      });
      handlegetNewsList()
    }

    const handelConfirmModal = (pramsId) =>{
      setShowConfirmModal({
        open:true,
        id:pramsId,
        type:"news"
      })
    }
      
    return(
      <>
        { auth !== null && <section className="position-relative">
            <ConfirmModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal} auth={auth} handleClose={handleClose}/>
            <h1>
              الاخبار
            </h1>
            <div className="d-flex justify-content-start mb-3">
              <Link to={'/addNews'} className="py-2 px-3 border rounded-pill text-decoration-none fs-5 text-secondary">
                <span className="ms-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                  </svg>
                </span>
                إضافة خبر جديد
              </Link>
            </div>
            <DataGridComponent rows={rows} columns={columns}/>
        </section>}
      </>
    )
}

