import React from 'react'
import './Login.scss'



export default function Login({
  handelFormChange,
  handleFormSubmit,
  form,
  formErrors,
  handleEnglishKeyPress,
  disabledBtn
}) {

  return (
    <section className='login' id='login'>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <div className="mt-3 position-relative">
            <label htmlFor="inputUser" className="form-label secondary-color fs-6">اسم المستخدم</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
              </svg>
            </span>
            <input type="text" className="form-control pe-5" id="inputUser" name="inputUser" aria-describedby="inputuser" value={form.inputUser} onChange={(e) => handelFormChange(e)} />
            <div className="pt-1 text-danger" id='inputuser'>
                <small>
                    {formErrors.inputUserErr}
                </small>
            </div>
        </div>
        <div className="mt-3 mb-3 position-relative">
            <label htmlFor="inputPassword" className="form-label secondary-color fs-6">كلمة المرور</label>
            <span className='position-absolute pass-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
              </svg>
            </span>
            <input type="password" className="form-control pe-5" id="inputPassword" name="inputPassword" aria-describedby="inputpassword" value={form.inputPassword} onChange={(e) => handelFormChange(e)} />
            <div className="pt-1 text-danger" id='inputpassword' onKeyDown={(e) =>handleEnglishKeyPress(e)}>
                <small>
                    {formErrors.inputPasswordErr}
                </small>
            </div>
        </div>

        <div className="d-flex justify-content-center">
          <button type="submit" className="btn rounded-pill text-white px-5 mx-3" disabled={disabledBtn ||formErrors.inputUserErr || formErrors.inputPasswordErr}>دخول</button>
        </div>
      </form>
  </section>
  )
}
