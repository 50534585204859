import { axiosInstance } from "../../network/axios";

export const getUsers =  (token,paginationModel) => async (dispatch) => {
    await axiosInstance.get(`/admin/users?page=${paginationModel.page}&limit=${paginationModel.pageSize}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_USERS_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const deleteUser =  async(id,token) => {
    await axiosInstance.delete(`/admin/users/${id}`, {
        headers: {
        authorization: token,
        },
    })
}
