const INITIAL_STATE = {
    levelsList : {
      rows:[],
      totalCount:0
    },
    programmelevelsList:[]
}

export default function levels(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_LEVELS_LIST":
        return {
            ...state,
            levelsList : action.payload 
        };
      case "GET_PROGRAMME_LEVELS_LIST":
        return {
            ...state,
            programmelevelsList : action.payload 
        };
      default:
        return state;
    }
  }