import React from 'react';
import './Register.scss';


import PhoneInput from 'react-phone-number-input';
import ar from 'react-phone-number-input/locale/ar';
import Select from 'react-select'
// import countryList from 'react-select-country-list'

export default function Register({
  countryListOptions,
  QuranListOptions,
  AcademicListOptions,
  maxDate,
  minDate,
  form,
  formErrors,
  handelFormChange,
  handlePhoneChange,
  handleNationalityChange,
  handleLiveChange,
  handleQuranChange,
  handleAcademicChange,
  handleFormSubmit,
  handleKeyPress,
  handleBack,
  disabledBtn,
  action
}) {
  
  return (
    <section className='register w-100' id='register'>
      <form onSubmit={(e) => handleFormSubmit(e)}>

        <div className="mt-3 position-relative">
            <label htmlFor="inputUser" className="form-label secondary-color fs-6">الاسم   ثلاثي</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
              </svg>
            </span>
            <input type="text" className={`form-control pe-5 secondary-color ${formErrors.inputUserErr?"inputErr":""}`} id="RegInputUser" name="inputUser" aria-describedby="inputuser" value={form.inputUser} onChange={(e) => handelFormChange(e)} placeholder='الاسم كاملا كما يظهر فى الشهادة' onKeyDown={(e) =>handleKeyPress(e)} />
            <div className="pt-1 text-danger" id='inputuser'>
                <small>
                    {formErrors.inputUserErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputBirthdate" className="form-label secondary-color fs-6">تاريخ الميلاد</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cake" viewBox="0 0 16 16">
                <path d="m7.994.013-.595.79a.747.747 0 0 0 .101 1.01V4H5a2 2 0 0 0-2 2v3H2a2 2 0 0 0-2 2v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a2 2 0 0 0-2-2h-1V6a2 2 0 0 0-2-2H8.5V1.806A.747.747 0 0 0 8.592.802zM4 6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v.414a.9.9 0 0 1-.646-.268 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0A.9.9 0 0 1 4 6.414zm0 1.414c.49 0 .98-.187 1.354-.56a.914.914 0 0 1 1.292 0c.748.747 1.96.747 2.708 0a.914.914 0 0 1 1.292 0c.374.373.864.56 1.354.56V9H4zM1 11a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.793l-.354.354a.914.914 0 0 1-1.293 0 1.914 1.914 0 0 0-2.707 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0L1 11.793zm11.646 1.854a1.915 1.915 0 0 0 2.354.279V15H1v-1.867c.737.452 1.715.36 2.354-.28a.914.914 0 0 1 1.292 0c.748.748 1.96.748 2.708 0a.914.914 0 0 1 1.292 0c.748.748 1.96.748 2.707 0a.914.914 0 0 1 1.293 0Z"/>
              </svg>
            </span>
            <input type="date" className={`form-control secondary-color ${formErrors.inputBirthdateErr?"inputErr":""}`} id="ReginputBirthdate" name="inputBirthdate" aria-describedby="inputBirthdate" value={form.inputBirthdate} onChange={(e) => handelFormChange(e)} max={maxDate} min={minDate}/>
            <div className="pt-1 text-danger" id='inputBirthdate'>
                <small>
                    {formErrors.inputBirthdateErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputGender" className="form-label secondary-color fs-6">الجنس</label>
            <div className='d-flex pe-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                </svg>
              </span>
              <input type="radio" name="inputGender" value={"male"} className='me-3 mt-2' checked={form.inputGender === "male"}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputGender" className="secondary-color fs-6 me-2 mt-2">ذكر</label>
              <input type="radio" name="inputGender" value={"female"} className='me-3 mt-2' checked={form.inputGender === "female"}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputGender" className="secondary-color fs-6 me-2 mt-2">أنثى</label>
            </div>
            <div className="pt-1 text-danger" id='inputGender'>
                <small>
                    {formErrors.inputGenderErr}
                </small>
            </div>
        </div>

        <div className='mt-3 position-relative'>
          <label htmlFor="phone" className="form-label secondary-color fs-6">رقم الهاتف</label>
          <PhoneInput
              labels={ar}
              name="ReginputPhone"
              id="ReginputPhone"
              value={form.inputPhone}
              onChange={handlePhoneChange}
              className='secondary-color'
              placeholder="رقم الهاتف الخاص بك للتواصل"
              />
          <div className="pt-1 text-danger" id='ReginputphoneErr'>
              <small>
                    {formErrors.inputPhoneErr}
                </small>
          </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputNationality" className="form-label secondary-color fs-6">الجنسية</label>
            <Select 
              className="selectCountry" 
              options={countryListOptions} 
              value={form.inputNationality?countryListOptions.find(e => e.value === form.inputNationality):""}  
              onChange={handleNationalityChange} 
              placeholder="اختر جنسيتك" 
              name='inputNationality'
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(76,125,120,.25)',
                  primary: 'rgba(76,125,120,.25)',
                },
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  boxShadow: state.isFocused ?'0 0 0 0.25rem rgba(76,125,120,.25)':'',
                  borderColor: state.isFocused?'#4c7d78': "hsl(0, 0%, 80%)",
                }),
                placeholder: (styles) => ({ ...styles, color:'#4c7d78' }),
                option: (styles) => ({ ...styles, color:'#4c7d78' }),
                singleValue: (styles, { data }) => ({ ...styles, color:'#4c7d78'})
              }}
            />
            <div className="pt-1 text-danger" id='inputnationality'>
                <small>
                    {formErrors.inputNationalityErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputLive" className="form-label secondary-color fs-6">بلد الأقامة</label>
            <Select 
              className="selectCountry"  
              name="inputLive" 
              id="inputLive"  
              options={countryListOptions} 
              value={form.inputLive?countryListOptions.find(e => e.value === form.inputLive):""}
              onChange={handleLiveChange} 
              placeholder="اختر مكان أقامتك"
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(76,125,120,.25)',
                  primary: 'rgba(76,125,120,.25)',
                },
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  boxShadow: state.isFocused ?'0 0 0 0.25rem rgba(76,125,120,.25)':'',
                  borderColor: state.isFocused?'#4c7d78': "hsl(0, 0%, 80%)",
                }),
                placeholder: (styles) => ({ ...styles, color:'#4c7d78' }),
                option: (styles) => ({ ...styles, color:'#4c7d78' }),
                singleValue: (styles, { data }) => ({ ...styles, color:'#4c7d78'})
              }}
            />
            <div className="pt-1 text-danger" id='inputlive'>
                <small>
                    {formErrors.inputLiveErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputQuran" className="form-label secondary-color fs-6">مقدار حفظك من القرآن الكريم</label>
            <Select 
              className="selectCountry" 
              name="inputQuran" 
              id="inputQuran" 
              options={QuranListOptions} 
              value={QuranListOptions.find(val=>val.value === form.inputQuran)} 
              onChange={handleQuranChange} 
              placeholder="أختر مقدار حفظك"
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(76,125,120,.25)',
                  primary: 'rgba(76,125,120,.25)',
                },
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  boxShadow: state.isFocused ?'0 0 0 0.25rem rgba(76,125,120,.25)':'',
                  borderColor: state.isFocused?'#4c7d78': "hsl(0, 0%, 80%)",
                }),
                placeholder: (styles) => ({ ...styles, color:'#4c7d78' }),
                option: (styles) => ({ ...styles, color:'#4c7d78'}),
                singleValue: (styles, { data }) => ({ ...styles, color:'#4c7d78'})
              }}
            />
            <div className="pt-1 text-danger" id='inputquran'>
                <small>
                    {formErrors.inputQuranErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputAcademic" className="form-label secondary-color fs-6">المستوى الاكاديمي</label>
            <Select 
              className="selectCountry" 
              name="inputAcademic" 
              id="inputAcademic" 
              options={AcademicListOptions} 
              value={AcademicListOptions.find(val=>val.value === form.inputAcademic)}  
              onChange={handleAcademicChange} 
              placeholder="أختر المستوى الدراسي"
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(76,125,120,.25)',
                  primary: 'rgba(76,125,120,.25)',
                },
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  boxShadow: state.isFocused ?'0 0 0 0.25rem rgba(76,125,120,.25)':'',
                  borderColor: state.isFocused?'#4c7d78': "hsl(0, 0%, 80%)",
                }),
                placeholder: (styles) => ({ ...styles, color:'#4c7d78' }),
                option: (styles) => ({ ...styles, color:'#4c7d78' }),
                singleValue: (styles, { data }) => ({ ...styles, color:'#4c7d78'})
              }}
            />
            <div className="pt-1 text-danger" id='inputacademic'>
                <small>
                    {formErrors.inputAcademicErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="ReginputEmail" className="form-label secondary-color fs-6">البريد الألكتروني</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
              </svg>
            </span>
            <input type="email" className={`form-control pe-5 secondary-color ${formErrors.inputEmailErr?"inputErr":""}`} id="ReginputEmail" name="ReginputEmail" aria-describedby="ReginputEmail" value={form.inputEmail} onChange={(e) => handelFormChange(e)} placeholder='بهذا الشكل : example@ayaat.com' readOnly={action === "editUser"} disabled={action === "editUser"}/>
            <div className="pt-1 text-danger" id='ReginputEmail'>
                <small>
                    {formErrors.inputEmailErr}
                </small>
            </div>
        </div>
        
        <div className="mt-3 position-relative">
            <label htmlFor="inputUsername" className="form-label secondary-color fs-6">اسم المستخدم</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
              </svg>
            </span>
            <input type="text" className={`form-control pe-5 secondary-color ${formErrors.inputUsernameErr?"inputErr":""}`} id="inputUsername" name="inputUsername" aria-describedby="inputusername" value={form.inputUsername} onChange={(e) => handelFormChange(e)} placeholder='الاسم المستخدم لتسجيل الدخول'/>
            <div className="pt-1 text-danger" id='inputusername'>
                <small>
                    {formErrors.inputUsernameErr}
                </small>
            </div>
        </div>

        <div className="d-flex justify-content-center my-5">
          <button 
            type="submit" 
            className="btn rounded-pill text-white px-5 mx-3"
            disabled={
              formErrors.inputUserErr ||
              formErrors.inputBirthdateErr ||
              formErrors.inputGenderErr ||
              formErrors.inputPhoneErr  ||
              formErrors.inputNationalityErr ||
              formErrors.inputLiveErr ||
              formErrors.inputQuranErr ||
              formErrors.inputAcademicErr  ||
              formErrors.inputEmailErr ||
              formErrors.inputUsernameErr ||
              disabledBtn
            }
          >{action === "addUser" ? "إنشاء":"حفظ"}</button>
          <button 
            type="button" 
            className="btn rounded-pill text-white px-5 mx-3"
            onClick={handleBack}
          >رجوع</button>
        </div>
      </form>
  </section>
  )
}
