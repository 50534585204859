const INITIAL_STATE = {
    programmesList : {
      rows:[],
      totalCount:0
    },
    programmesLevelList: [],
    programmesStageList: []
}

export default function programmes(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_PROGRAMMES_LIST":
        return {
            ...state,
            programmesList : action.payload
        };
      case "GET_PROGRAMMES_LEVEL_LIST":
        return {
            ...state,
            programmesLevelList : action.payload 
        };
      case "GET_PROGRAMMES_STAGE_LIST":
        return {
            ...state,
            programmesStageList : action.payload 
        };
      default:
        return state;
    }
  }