const INITIAL_STATE = {
    booksList : {
      rows:[],
      totalCount:0
    }
}

export default function books(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_BOOKS_LIST":
        return {
            ...state,
            booksList : action.payload 
        };
      default:
        return state;
    }
  }