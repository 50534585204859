import { axiosInstance } from "../../network/axios";


export const PostNews =  async (news , token) =>{
    await axiosInstance.post("/admin/news",news ,{
        headers: {
        authorization: token,
        },
    });
}

export const getNewsList =  (token) => async (dispatch) => {
    await axiosInstance.get("/admin/news", {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_NEWS_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const getNews =  (id,token) => async (dispatch) => {
    await axiosInstance.get(`/admin/news/${id}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_NEWS",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const PatchNews =  async (id , user , token) =>{
    await axiosInstance.patch(`/admin/news/${id}`,user ,{
        headers: {
        authorization: token,
        },
    });
}

export const deleteNews =  async(id,token) => {
    await axiosInstance.delete(`/admin/news/${id}`, {
        headers: {
        authorization: token,
        },
    })
}