import { useState,useMemo} from 'react';
import * as React from 'react';
import { DataGrid,GridToolbar, gridClasses } from '@mui/x-data-grid';
import { arSD } from '@mui/x-data-grid/locales';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';


export default function DataGridComponent({
    columns,
    rows,
    setRowId,
    loading,
    rowCount,
    paginationModel,
    setPaginationModel
  }) {
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const existingTheme = useTheme();
    const theme = useMemo(() =>
        createTheme({}, arSD, existingTheme, {
          direction: 'rtl',
        }),
      [existingTheme],
    );

    return(
      <ThemeProvider theme={theme}>
        <div dir='rtl' style={{ height: 475, width: '100%' }}>
          <DataGrid
            loading={loading}
            rows={rows} 
            columns={columns}
            getRowId={row=>row.id}
            onCellEditStart={params=>setRowId(params.id)}
            pagination
            rowCount={rowCount}
            pageSizeOptions={[5,10,20]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick
            disableColumnFilter
            disableDensitySelector
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
            }
            slots={{ toolbar: GridToolbar }}
            sx={{
                boxShadow: 2,
                '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
                },
                '& .MuiDataGrid-cell':{
                    textAlign:'center',
                    border:'none'
                },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                    outline: 'none',
                  },
                  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                    {
                      outline: 'none',
                    },
                '& .MuiTablePagination-selectLabel':{
                    marginBottom:'0'
                },
                '& .MuiTablePagination-displayedRows':{
                    marginBottom:'0'
                },
                '& .MuiDataGrid-columnHeaderTitleContainer':{
                  justifyContent:'center'
                },
              }}
          />
        </div>
      </ThemeProvider>
    )
}

