import React from 'react';
import './News.scss';

export default function News({
  form,
  formErrors,
  handelFormChange,
  handleFormSubmit,
  disabledBtn,
  action
}) {
  
  return (
    <section className='programme w-50'>
      <form onSubmit={(e) => handleFormSubmit(e)}>

        <div className="mt-3 position-relative">
            <label htmlFor="inputTitle" className="form-label secondary-color fs-6">عنوان الخبر</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-book" viewBox="0 0 16 16">
                <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
              </svg>
            </span>
            <input type="text" className={`form-control pe-5 secondary-color ${formErrors.inputTitleErr?"inputErr":""}`} name="inputTitle" aria-describedby="inputTitle" value={form.inputTitle} onChange={(e) => handelFormChange(e)}/>
            <div className="pt-1 text-danger">
                <small>
                    {formErrors.inputTitleErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputDescText" className="form-label secondary-color fs-6">وصف الخبر</label>
            <textarea type="text" className={`form-control secondary-color ${formErrors.inputDescTextErr?"inputErr":""}`} name="inputDescText" aria-describedby="inputDescText" value={form.inputDescText} onChange={(e) => handelFormChange(e)}/>
            <div className="pt-1 text-danger">
                <small>
                    {formErrors.inputDescTextErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputVideoLink" className="form-label secondary-color fs-6">رابط فيديو الخبر</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/>
              </svg>
            </span>
            <input type="text" className={`form-control pe-5  secondary-color ${formErrors.inputVideoLinkErr?"inputErr":""}`} name="inputVideoLink" aria-describedby="inputVideoLink" value={form.inputVideoLink} onChange={(e) => handelFormChange(e)}/>
            <div className="pt-1 text-danger">
                <small>
                    {formErrors.inputVideoLinkErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputShowIntro" className="form-label secondary-color fs-6">إظهار الخبر فى الموقع</label>
            <input type="checkbox" className={`form-check-input me-5  secondary-color ${formErrors.inputShowIntroErr?"inputErr":""}`} name="inputShowIntro" aria-describedby="inputShowIntro" onChange={(e) => handelFormChange(e)} checked={form.inputShowIntro}/>
            <div className="pt-1 text-danger">
                <small>
                    {formErrors.inputShowIntroErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputActive" className="form-label secondary-color fs-6">تفعيل الخبر</label>
            <input type="checkbox" className={`form-check-input me-5  secondary-color ${formErrors.inputActiveErr?"inputErr":""}`} name="inputActive" aria-describedby="inputActive" onChange={(e) => handelFormChange(e)} checked={form.inputActive}/>
            <div className="pt-1 text-danger">
                <small>
                    {formErrors.inputActiveErr}
                </small>
            </div>
        </div>

        <div className="d-flex justify-content-center">
          <button 
            type="submit" 
            className="btn rounded-pill text-white px-5 mx-3"
            disabled={
              formErrors.inputTitleErr ||
              formErrors.inputDescTextErr ||
              formErrors.inputVideoLink ||
              disabledBtn
            }
          >{action === "addNews" ? "إنشاء خبر جديد":"تعديل الخبر"}</button>
        </div>
      </form>
  </section>
  )
}

