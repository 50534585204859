import './App.scss';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Header from './Components/Header/Header';
import Home from './pages/Home/Home';
import Users from './pages/Users/Users';
import EditUser from './pages/EditUser/EditUser';
import AddUser from './pages/AddUser/AddUser';
import Programmes from './pages/Programmes/Programmes';
import AddProgramme from './pages/AddProgramme/AddProgramme';
import EditProgramme from './pages/EditProgramme/EditProgramme';
import Levels from './pages/Levels/Levels';
import AddLevel from './pages/AddLevel/AddLevel';
import EditLevel from './pages/EditLevel/EditLevel';
import Stages from './pages/Stages/Stages';
import AddStage from './pages/AddStage/AddStage';
import EditStage from './pages/EditStage/EditStage';
import Books from './pages/Books/Books';
import AddBook from './pages/AddBook/AddBook';
import EditBook from './pages/EditBook/EditBook';
import Courses from './pages/Courses/Courses';
import AddCourse from './pages/AddCourse/AddCourse';
import EditCourse from './pages/EditCourse/EditCourse';
import Admins from './pages/Admins/Admins';
import EditAdmin from './pages/EditAdmin/EditAdmin';
import AddAdmin from './pages/AddAdmin/AddAdmin';
import News from './pages/News/News';
import EditNews from './pages/EditNews/EditNews';
import AddNews from './pages/AddNews/AddNews';
import NotFound from './pages/NotFound/NotFound';
import Accept from './pages/Accept/Accept';
import ShowNavbar from './Components/ShowNavbar/ShowNavbar';

export default function App() {
  return (
    <BrowserRouter>
      <Header/>
      <section className='row mx-0 position-relative'>
        <ShowNavbar>
          <Navbar />
        </ShowNavbar>
        <section className="page-section col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <Switch>
            <Route path={'/'} exact component={Home} />
            <Route path={'/users'} exact component={Users} />
            <Route path={'/user'} exact component={AddUser} />
            <Route path={'/user/:id'} exact component={EditUser} />
            <Route path={'/programmes'} exact component={Programmes} />
            <Route path={'/programme'} exact component={AddProgramme} />
            <Route path={'/programme/:id'} exact component={EditProgramme} />
            <Route path={'/levels'} exact component={Levels} />
            <Route path={'/level'} exact component={AddLevel} />
            <Route path={'/level/:id'} exact component={EditLevel} />
            <Route path={'/stages'} exact component={Stages} />
            <Route path={'/stage'} exact component={AddStage} />
            <Route path={'/stage/:id'} exact component={EditStage} />
            <Route path={'/books'} exact component={Books} />
            <Route path={'/book'} exact component={AddBook} />
            <Route path={'/book/:id'} exact component={EditBook} />
            <Route path={'/courses'} exact component={Courses} />
            <Route path={'/course'} exact component={AddCourse} />
            <Route path={'/course/:id'} exact component={EditCourse} />
            <Route path={'/admins'} exact component={Admins} />
            <Route path={'/admin'} exact component={AddAdmin} />
            <Route path={'/admin/:id'} exact component={EditAdmin} />
            <Route path={'/news'} exact component={News} />
            <Route path={'/addNews'} exact component={AddNews} />
            <Route path={'/news/:id'} exact component={EditNews} />
            <Route path={'/acceptence'} exact component={Accept} />
            <Route path={'*'} exact component={NotFound} />
          </Switch>
        </section>
      </section>
    </BrowserRouter>
  );
}