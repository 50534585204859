import React from 'react';
import './Programme.scss';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'

export default function Programme({
  form,
  formErrors,
  quill,
  quillFormats,
  handelFormChange,
  handleFormSubmit,
  handleDescTextChange,
  handleDetailsTextChange,
  handleKeyPress,
  handleEnglishKeyPress,
  handleBack,
  disabledBtn,
  action
}) {
  
  return (
      <section className='programme w-50'>
        <form onSubmit={(e) => handleFormSubmit(e)}>

          <div className="mt-3 position-relative">
              <label htmlFor="inputTitle" className="form-label secondary-color fs-6">* اسم البرنامج</label>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-book" viewBox="0 0 16 16">
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
                </svg>
              </span>
              <input type="text" className={`form-control px-5 secondary-color ${formErrors.inputTitleErr?"inputErr":""}`} name="inputTitle" aria-describedby="inputtitle" value={form.inputTitle} onChange={(e) => handelFormChange(e)}  onKeyDown={(e) =>handleKeyPress(e)}/>
              <div className="pt-1 text-danger" id='inputtitle'>
                  <small>
                      {formErrors.inputTitleErr}
                  </small>
              </div>
          </div>

          <div className="my-3 position-relative">
              <label htmlFor="inputDescText" className="form-label secondary-color fs-6">* وصف البرنامج</label>
              <ReactQuill
              theme="snow"
              modules={quill}
              formats={quillFormats}
              placeholder="ادخل وصف البرنامج ...."
              onChange={(e) => handleDescTextChange(e)}
              // style={{ height: "220px"}}
              className={`secondary-color ${formErrors.inputDescTextErr?"inputErr":""}`}
              name="inputDescText" 
              aria-describedby="inputdescText" 
              value={form.inputDescText}
              onKeyDown={(e) =>handleKeyPress(e)}
              ></ReactQuill>
              <div className="pt-1 text-danger" id='inputdescText'>
                  <small>
                      {formErrors.inputDescTextErr}
                  </small>
              </div>
          </div>

          <div className="mt-3 position-relative">
              <label htmlFor="inputVideoLink" className="form-label secondary-color fs-6">* رابط فيديو وصف البرنامج</label>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
                  <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                  <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z"/>
                </svg>
              </span>
              <input type="text" className={`form-control px-5  secondary-color ${formErrors.inputVideoLinkErr?"inputErr":""}`} name="inputVideoLink" aria-describedby="inputVideoLink" value={form.inputVideoLink} onChange={(e) => handelFormChange(e)} onKeyDown={(e) =>handleEnglishKeyPress(e)}/>
              <div className="pt-1 text-danger" id='inputvideolink'>
                  <small>
                      {formErrors.inputVideoLinkErr}
                  </small>
              </div>
          </div>

          <div className="mt-3 position-relative">
            <label htmlFor="inputPDF" className="form-label secondary-color fs-6">* الملف التعريفي للبرنامج</label>
            <input type="file" className={`form-control  secondary-color ${formErrors.inputPDFErr?"inputErr":""}`} name="inputPDF" aria-describedby="inputPDF" onChange={(e) => handelFormChange(e)} accept="application/pdf"/>
            <div className="pt-1 text-danger" id='inputpdf'>
                <small>
                    {formErrors.inputPDFErr}
                </small>
            </div>
          </div>

          <div className="my-3 position-relative">
              <label htmlFor="inputDetailsText" className="form-label secondary-color fs-6">* تفاصيل البرنامج</label>
              <ReactQuill
              theme="snow"
              modules={quill}
              formats={quillFormats}
              placeholder="ادخل تفاصيل البرنامج ...."
              onChange={(e) => handleDetailsTextChange(e)}
              // style={{ height: "220px"}}
              className={`secondary-color ${formErrors.inputDetailsTextErr?"inputErr":""}`}
              name="inputDetailsText" 
              aria-describedby="inputdetailstext" 
              value={form.inputDetailsText}
              onKeyDown={(e) =>handleKeyPress(e)}
              ></ReactQuill>
              <div className="pt-1 text-danger" id='inputdetailstext'>
                  <small>
                      {formErrors.inputDetailsTextErr}
                  </small>
              </div>
            </div>

            <div className="mt-3 position-relative">
              <label htmlFor="inputIntroImage" className="form-label secondary-color fs-6">* صورة البرنامج</label>
              <input type="file" className={`form-control  secondary-color ${formErrors.inputIntroImageErr?"inputErr":""}`} name="inputIntroImage" aria-describedby="inputintroimage" onChange={(e) => handelFormChange(e)} accept=".jpg,.jpeg,.png"/>
              <div className="pt-1 text-danger" id='inputintroimage'>
                  <small>
                      {formErrors.inputIntroImageErr}
                  </small>
              </div>
            </div>

            <div className="mt-3 position-relative">
              <label htmlFor="inputLogoImage" className="form-label secondary-color fs-6">* لوجو الربنامج</label>
              <input type="file" className={`form-control  secondary-color ${formErrors.inputLogoImageErr?"inputErr":""}`} name="inputLogoImage" aria-describedby="inputlogoimage" onChange={(e) => handelFormChange(e)} accept=".jpg,.jpeg,.png"/>
              <div className="pt-1 text-danger" id='inputlogoimage'>
                  <small>
                      {formErrors.inputLogoImageErr}
                  </small>
              </div>
            </div>

          <div className="mt-3 position-relative">
              <label htmlFor="inputEnrollStatus" className="form-label secondary-color fs-6">* طريقة الاشتراك</label>
              <div className='d-flex px-5'>
                <span className='position-absolute user-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet2" viewBox="0 0 16 16">
                    <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"/>
                  </svg>
                </span>
                <input type="radio" name="inputEnrollStatus" value={"free"} className='me-3 mt-2' checked={form.inputEnrollStatus === "free"}  onChange={(e) => handelFormChange(e)}/>
                <label htmlFor="inputEnrollStatus" className="secondary-color fs-6 me-2 mt-2">مجاني</label>
                <input type="radio" name="inputEnrollStatus" value={"paied"} className='me-3 mt-2' checked={form.inputEnrollStatus === "paied"}  onChange={(e) => handelFormChange(e)}/>
                <label htmlFor="inputEnrollStatus" className="secondary-color fs-6 me-2 mt-2">مدفوع</label>
              </div>
              <div className="pt-1 text-danger" id='inputenrollstatus'>
                  <small>
                      {formErrors.inputEnrollStatusErr}
                  </small>
              </div>
          </div>

          {
            form.inputEnrollStatus === "paied" && 
            <div className="mt-3 position-relative">
              <label htmlFor="inputPrice" className="form-label secondary-color fs-6">* سعر البرنامج</label>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cash" viewBox="0 0 16 16">
                  <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                  <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2z"/>
                </svg>
              </span>
              <input type="number" className={`form-control px-5 secondary-color ${formErrors.inputPriceErr?"inputErr":""}`} name="inputPrice" aria-describedby="inputprice" value={form.inputPrice} onChange={(e) => handelFormChange(e)} min={0}/>
              <div className="pt-1 text-danger" id='inputprice'>
                  <small>
                      {formErrors.inputPriceErr}
                  </small>
              </div>
            </div>
          }

          <div className="mt-3 position-relative">
            <label htmlFor="inputStartType" className="form-label secondary-color fs-6">* وقت البرنامج </label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                  <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z"/>
                  <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                </svg>
              </span>
              <input type="radio" name="inputStartType" value={"anytime"} className='me-3 mt-2' checked={form.inputStartType === "anytime"}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputStartType" className="secondary-color fs-6 me-2 mt-2">غير محدد بوقت</label>
              <input type="radio" name="inputStartType" value={"Timebound"} className='me-3 mt-2' checked={form.inputStartType === "Timebound"}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputStartType" className="secondary-color fs-6 me-2 mt-2">محدد بوقت</label>
            </div>
            <div className="pt-1 text-danger" id='inputstarttype'>
                <small>
                    {formErrors.inputStartTypeErr}
                </small>
            </div>
          </div>
          {
            form.inputStartType === "Timebound" &&
            <div>
              <div className="mt-3 position-relative">
                <label htmlFor="inputStartDate" className="form-label secondary-color fs-6">تاريخ بدأ البرنامج</label>
                <span className='position-absolute user-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-plus" viewBox="0 0 16 16">
                    <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                  </svg>
                </span>
                <input type="date" className={`form-control secondary-color ${formErrors.inputStartDateErr?"inputErr":""}`} id="inputStartDate" name="inputStartDate" aria-describedby="inputstartdate" value={form.inputStartDate} onChange={(e) => handelFormChange(e)} min={form.inputStartDate?form.inputStartDate:new Date().toISOString().split("T")[0]}/>
                <div className="pt-1 text-danger" id='inputstartdate'>
                    <small>
                        {formErrors.inputStartDateErr}
                    </small>
                </div>
              </div>

              <div className="mt-3 position-relative">
                <label htmlFor="inputEndDate" className="form-label secondary-color fs-6">تاريخ انتهاء البرنامج</label>
                <span className='position-absolute user-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-plus" viewBox="0 0 16 16">
                    <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                  </svg>
                </span>
                <input type="date" className={`form-control secondary-color ${formErrors.inputEndDateErr?"inputErr":""}`} id="inputEndDate" name="inputEndDate" aria-describedby="inputenddate" value={form.inputEndDate} onChange={(e) => handelFormChange(e)} min={form.inputStartDate?form.inputStartDate:new Date().toISOString().split("T")[0]}/>
                <div className="pt-1 text-danger" id='inputenddate'>
                    <small>
                        {formErrors.inputEndDateErr}
                    </small>
                </div>
              </div>
            </div>
          }

          <div className="mt-3 position-relative">
            <label htmlFor="inputPresenter" className="form-label secondary-color fs-6">* مقدم البرنامج</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
              </svg>
            </span>
            <input type="text" className={`form-control px-5 secondary-color ${formErrors.inputPresenterErr?"inputErr":""}`} name="inputPresenter" aria-describedby="inputpresenter" value={form.inputPresenter} onChange={(e) => handelFormChange(e)}/>
            <div className="pt-1 text-danger" id='inputpresenter'>
                <small>
                    {formErrors.inputPresenterErr}
                </small>
            </div>
          </div>

          <div className="mt-3 position-relative">
              <label htmlFor="inputProgrammeScore" className="form-label secondary-color fs-6">* مجموع الدرجات للبرنامج</label>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-binary" viewBox="0 0 16 16">
                  <path d="M5.526 13.09c.976 0 1.524-.79 1.524-2.205 0-1.412-.548-2.203-1.524-2.203-.978 0-1.526.79-1.526 2.203 0 1.415.548 2.206 1.526 2.206zm-.832-2.205c0-1.05.29-1.612.832-1.612.358 0 .607.247.733.721L4.7 11.137a7 7 0 0 1-.006-.252m.832 1.614c-.36 0-.606-.246-.732-.718l1.556-1.145q.005.12.005.249c0 1.052-.29 1.614-.829 1.614m5.329.501v-.595H9.73V8.772h-.69l-1.19.786v.688L8.986 9.5h.05v2.906h-1.18V13h3z"/>
                  <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"/>
                </svg>
              </span>
              <input type="number" className={`form-control px-5 secondary-color ${formErrors.inputProgrammeScoreErr?"inputErr":""}`} name="inputProgrammeScore" aria-describedby="inputprogrammescore" value={form.inputProgrammeScore} onChange={(e) => handelFormChange(e)} min={0}/>
              <div className="pt-1 text-danger" id='inputprogrammescore'>
                  <small>
                      {formErrors.inputProgrammeScoreErr}
                  </small>
              </div>
            </div>

            <div className="mt-3 position-relative">
              <label htmlFor="inputPassScore" className="form-label secondary-color fs-6">* درجة النجاح للبرنامج</label>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-binary" viewBox="0 0 16 16">
                  <path d="M5.526 13.09c.976 0 1.524-.79 1.524-2.205 0-1.412-.548-2.203-1.524-2.203-.978 0-1.526.79-1.526 2.203 0 1.415.548 2.206 1.526 2.206zm-.832-2.205c0-1.05.29-1.612.832-1.612.358 0 .607.247.733.721L4.7 11.137a7 7 0 0 1-.006-.252m.832 1.614c-.36 0-.606-.246-.732-.718l1.556-1.145q.005.12.005.249c0 1.052-.29 1.614-.829 1.614m5.329.501v-.595H9.73V8.772h-.69l-1.19.786v.688L8.986 9.5h.05v2.906h-1.18V13h3z"/>
                  <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"/>
                </svg>
              </span>
              <input type="number" className={`form-control px-5 secondary-color ${formErrors.inputPassScoreErr?"inputErr":""}`} name="inputPassScore" aria-describedby="inputpassscore" value={form.inputPassScore} onChange={(e) => handelFormChange(e)} min={0}/>
              <div className="pt-1 text-danger" id='inputpassscore'>
                  <small>
                      {formErrors.inputPassScoreErr}
                  </small>
              </div>
            </div>

          <div className="mt-3 position-relative">
            <label htmlFor="inputMainProgramme" className="form-label secondary-color fs-6">البرنامج الأساسي</label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmark-star" viewBox="0 0 16 16">
                  <path d="M7.84 4.1a.178.178 0 0 1 .32 0l.634 1.285a.18.18 0 0 0 .134.098l1.42.206c.145.021.204.2.098.303L9.42 6.993a.18.18 0 0 0-.051.158l.242 1.414a.178.178 0 0 1-.258.187l-1.27-.668a.18.18 0 0 0-.165 0l-1.27.668a.178.178 0 0 1-.257-.187l.242-1.414a.18.18 0 0 0-.05-.158l-1.03-1.001a.178.178 0 0 1 .098-.303l1.42-.206a.18.18 0 0 0 .134-.098z"/>
                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z"/>
                </svg>
              </span>
              <input type="checkbox" name="inputMainProgramme" className='me-3 mt-2' checked={form.inputMainProgramme}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputMainProgramme" className="secondary-color fs-6 me-2 mt-2">نعم هذا البرنامج الأساسى</label>
            </div>
          </div>
          
          <div className="mt-3 position-relative">
            <label htmlFor="inputHasCertification" className="form-label secondary-color fs-6">شهادة البرنامج</label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mortarboard" viewBox="0 0 16 16">
                  <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917zM8 8.46 1.758 5.965 8 3.052l6.242 2.913z"/>
                  <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46z"/>
                </svg>
              </span>
              <input type="checkbox" name="inputHasCertification" className='me-3 mt-2' checked={form.inputHasCertification}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputHasCertification" className="secondary-color fs-6 me-2 mt-2">نعم للبرنامج شهادة</label>
            </div>
          </div>

          <div className="mt-3 position-relative">
            <label htmlFor="inputHasExam" className="form-label secondary-color fs-6">امتحان البرنامج</label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-text" viewBox="0 0 16 16">
                  <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"/>
                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
                </svg>
              </span>
              <input type="checkbox" name="inputHasExam" className='me-3 mt-2' checked={form.inputHasExam}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputHasExam" className="secondary-color fs-6 me-2 mt-2">نعم للبرنامج امتحان للنجاح</label>
            </div>
          </div>

          <div className="mt-3 position-relative">
            <label htmlFor="inputHasLevel" className="form-label secondary-color fs-6">مراحل البرنامج</label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmarks" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1z"/>
                  <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1"/>
                </svg>
              </span>
              <input type="checkbox" name="inputHasLevel" className='me-3 mt-2' checked={form.inputHasLevel}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputHasLevel" className="secondary-color fs-6 me-2 mt-2">نعم للبرنامج مراحل</label>
            </div>
          </div>

          <div className="mt-3 position-relative">
            <label htmlFor="inputHasStage" className="form-label secondary-color fs-6">مستويات البرنامج</label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmarks" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1z"/>
                  <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1"/>
                </svg>
              </span>
              <input type="checkbox" name="inputHasStage" className='me-3 mt-2' checked={form.inputHasStage}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputHasStage" className="secondary-color fs-6 me-2 mt-2">نعم للبرنامج مستويات</label>
            </div>
          </div>

          <div className="mt-3 position-relative">
            <label htmlFor="inputHasBook" className="form-label secondary-color fs-6">كتب البرنامج</label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmarks" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1z"/>
                  <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1"/>
                </svg>
              </span>
              <input type="checkbox" name="inputHasBook" className='me-3 mt-2' checked={form.inputHasBook}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputHasBook" className="secondary-color fs-6 me-2 mt-2">نعم للبرنامج كتب</label>
            </div>
          </div>

          <div className="mt-3 position-relative">
            <label htmlFor="inputHasContent" className="form-label secondary-color fs-6">محتوى البرنامج</label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmarks" viewBox="0 0 16 16">
                  <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1z"/>
                  <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1"/>
                </svg>
              </span>
              <input type="checkbox" name="inputHasContent" className='me-3 mt-2' checked={form.inputHasContent}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputHasContent" className="secondary-color fs-6 me-2 mt-2">نعم للبرنامج محتوى</label>
            </div>
          </div>

          <div className="mt-3 position-relative">
            <label htmlFor="inputHasRating" className="form-label secondary-color fs-6">تقييم البرنامج</label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                </svg>
              </span>
              <input type="checkbox" name="inputHasRating" className='me-3 mt-2' checked={form.inputHasRating}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputHasRating" className="secondary-color fs-6 me-2 mt-2">نعم للبرنامج تقييم</label>
            </div>
          </div>

          <div className="mt-3 position-relative">
            <label htmlFor="inputShowFront" className="form-label secondary-color fs-6">اظهار البرنامج فى الموقع</label>
            <div className='d-flex px-5'>
              <span className='position-absolute user-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-display" viewBox="0 0 16 16">
                  <path d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4q0 1 .25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75Q6 13 6 12H2s-2 0-2-2zm1.398-.855a.76.76 0 0 0-.254.302A1.5 1.5 0 0 0 1 4.01V10c0 .325.078.502.145.602q.105.156.302.254a1.5 1.5 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.76.76 0 0 0 .254-.302 1.5 1.5 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.76.76 0 0 0-.302-.254A1.5 1.5 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145"/>
                </svg>
              </span>
              <input type="checkbox" name="inputShowFront" className='me-3 mt-2' checked={form.inputShowFront}  onChange={(e) => handelFormChange(e)}/>
              <label htmlFor="inputShowFront" className="secondary-color fs-6 me-2 mt-2">نعم اظهر البرنامج</label>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-5">
            <button 
              type="submit" 
              className="btn rounded-pill text-white px-5 mx-3"
              disabled={
                formErrors.inputTitleErr ||
                formErrors.inputDescTextErr ||
                formErrors.inputVideoLinkErr ||
                formErrors.inputPDFErr ||
                formErrors.inputDetailsTextErr ||
                formErrors.inputIntroImageErr ||
                formErrors.inputLogoImageErr ||
                formErrors.inputEnrollStatusErr ||
                formErrors.inputPriceErr ||
                formErrors.inputStartTypeErr ||
                formErrors.inputStartDateErr ||
                formErrors.inputEndDateErr ||
                formErrors.inputPresenterErr ||
                formErrors.inputProgrammeScoreErr ||
                formErrors.inputPassScoreErr ||
                disabledBtn
              }
            >{action === "addProgramme" ? "إنشاء برنامج جديد":"تعديل البرنامج"}</button>
            <button 
              type="button" 
              className="btn rounded-pill text-white px-5 mx-3"
              onClick={handleBack}
            >رجوع</button>
          </div>
        </form>
    </section>
  )
}

