import React, { useState, useEffect } from 'react';
import Programme from '../../Components/Programme/Programme'

import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { axiosInstance } from "../../network/axios";

export default function AddProgramme() {
    const history = useHistory();
    let auth  = useSelector(state => state.auth);
    const numberRegex = /^\d*\.?\d{0,2}$/;
    const namePattern=new RegExp("^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]+( [\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]+)*$")
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?' + // port
        '(\\/[-a-z\\d%_.~+]*)*' + // path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i' // fragment locator
      );
    const [disabledBtn, setdisabledBtn] = useState(true);

    const quill = {
        toolbar: [
            // [{ 'direction': 'rtl' }, { 'direction': 'ltr' }],
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
        //   ["link", "image"],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
      };

      const quillFormats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
      ];

    const [form, setForm] = useState({
        inputTitle: "",
        inputDescText: "",
        inputVideoLink: "",
        inputPDF: "",
        inputDetailsText: "",
        inputIntroImage: "",
        inputLogoImage: "",
        inputEnrollStatus: "",
        inputPrice: "",
        inputStartType: "",
        inputStartDate: "",
        inputEndDate: "",
        inputPresenter: "",
        inputProgrammeScore: "",
        inputPassScore: "",
        inputMainProgramme: false,
        inputHasCertification: false,
        inputHasExam: false,
        inputHasLevel: false,
        inputHasStage: false,
        inputHasBook: false,
        inputHasContent: false,
        inputHasRating: false,
        inputShowFront: false,
    });

    const [formErrors, setFormError] = useState({
        inputTitleErr: null,
        inputDescTextErr: null,
        inputVideoLinkErr: null,
        inputPDFErr: null,
        inputDetailsTextErr: null,
        inputIntroImageErr: null,
        inputLogoImageErr: null,
        inputEnrollStatusErr: null,
        inputPriceErr: null,
        inputStartTypeErr: null,
        inputStartDateErr: null,
        inputEndDateErr: null,
        inputPresenterErr: null,
        inputProgrammeScoreErr: null,
        inputPassScoreErr: null
    });

    useEffect(()=>{
        if(auth === null){
            history.push('/');
        }
        return () => {
            handleClearForm()
        };
    },[auth, history])

    const handleKeyPress = (event) => {
        if (
            !(
                /^[\u0600-\u06FF\s]$/.test(event.key) || 
                event.key === 'Backspace' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    };

    const handleEnglishKeyPress = (event) => {
        if (
            !(
                /^[a-zA-Z0-9:/?.#-_]*$/.test(event.key) || 
                event.key === 'Backspace' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    };

    const handelFormChange = (e) => {
      if (e.target.name === "inputTitle") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputTitle: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputTitleErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : /^\s|\s$/.test(e.target.value)
                    ? "* يجب ان لا يبدأ او ينتهى الاسم بمسافة"
                    : /\s{2,}/.test(e.target.value)
                    ? "* يجب ان لا يحتوى الاسم على مسافتين"
                    : !namePattern.test(e.target.value)
                    ? "* يجب كتابة الاسم باللغة العربية ولا يحتوى على اى رموز او ارقام"
                    : null,
        });
      } 
      else if (e.target.name === "inputVideoLink") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputVideoLink: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputVideoLinkErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : !urlPattern.test(e.target.value)
                      ? "* يجب ان يكون اللينك مكتوب بطريقة صحيحة"
                      : null,
          });
      } 
      else if (e.target.name === "inputPDF") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputPDF: e.target.files[0],
        });
        setFormError({
            ...formErrors,
            inputPDFErr:
                e.target.files.length === 0
                    ? "* هذا الحقل مطلوب"
                    : e.target.files[0]?.type !== 'application/pdf'
                    ? "* يبجب ان تكون صيغة الملف .pdf"
                    : null,
        });
      }
      else if (e.target.name === "inputIntroImage") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputIntroImage: e.target.files[0],
        });
        setFormError({
            ...formErrors,
            inputIntroImageErr:
                e.target.files.length === 0
                    ? "* هذا الحقل مطلوب"
                    : !e.target.files[0]?.type.startsWith('image/')
                    ? "* يبجب ان تكون صيغة الملف .jpg .jpeg .png"
                    : !/(\.jpg|\.jpeg|\.png)$/i.exec(e.target.files[0]?.name)
                    ? "* يبجب ان تكون صيغة الملف .jpg .jpeg .png"
                    : null,
        });
      }
      else if (e.target.name === "inputLogoImage") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputLogoImage: e.target.files[0],
        });
        setFormError({
            ...formErrors,
            inputLogoImageErr:
                e.target.files.length === 0
                    ? "* هذا الحقل مطلوب"
                    : !e.target.files[0]?.type.startsWith('image/')
                    ? "* يبجب ان تكون صيغة الملف .jpg .jpeg .png"
                    : !/(\.jpg|\.jpeg|\.png)$/i.exec(e.target.files[0]?.name)
                    ? "* يبجب ان تكون صيغة الملف .jpg .jpeg .png"
                    : null,
        });
      }
      else if (e.target.name === "inputEnrollStatus") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputEnrollStatus: e.target.value,
              inputPrice : e.target.value === "free" ? "" : form.inputPrice,
          });
          setFormError({
              ...formErrors,
              inputEnrollStatusErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : null,
          });
      }
      else if (e.target.name === "inputPrice" && form.inputEnrollStatus === "paied") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputPrice: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputPriceErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : !numberRegex.test(e.target.value)
                      ? "* يجب ان تكون القيمة رقم صحيح"
                      : null,
          });
      }
      else if (e.target.name === "inputStartType") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputStartType: e.target.value,
              inputStartDate:e.target.value === "anytime"?"":form.inputStartDate,
              inputEndDate:e.target.value === "anytime"?"":form.inputEndDate
          });
          setFormError({
              ...formErrors,
              inputStartTypeErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : null,
          });
      }
      else if (e.target.name === "inputStartDate" && form.inputStartType === "Timebound") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputStartDate: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputStartDateErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : null,
          });
      }
      else if (e.target.name === "inputEndDate" && form.inputStartType === "Timebound") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputEndDate: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputEndDateErr:
                      new Date(e.target.value) < new Date(form.inputStartDate)
                      ? "* يجب ان يكون تاريخ الانتهاء بعد تاريخ البدء"
                      : null,
          });
      }
      else if (e.target.name === "inputPresenter") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputPresenter: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputPresenterErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : /^\s|\s$/.test(e.target.value)
                    ? "* يجب ان لا يبدأ او ينتهى الاسم بمسافة"
                    : /\s{2,}/.test(e.target.value)
                    ? "* يجب ان لا يحتوى الاسم على مسافتين"
                    : !namePattern.test(e.target.value)
                    ? "* يجب كتابة الاسم باللغة العربية ولا يحتوى على اى رموز او ارقام"
                    : null,
        });
      }
      else if (e.target.name === "inputProgrammeScore") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputProgrammeScore: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputProgrammeScoreErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : !numberRegex.test(e.target.value)
                    ? "* يجب ان تكون القيمة رقم صحيح"
                    : null,
        });
      }
      else if (e.target.name === "inputPassScore") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputPassScore: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputPassScoreErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : !numberRegex.test(e.target.value)
                    ? "* يجب ان تكون القيمة رقم صحيح"
                    : null,
        });
      }
      else if (e.target.name === "inputMainProgramme") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputMainProgramme: e.target.checked,
        });
      }
      else if (e.target.name === "inputHasCertification") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputHasCertification: e.target.checked,
        });
      }
      else if (e.target.name === "inputHasExam") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputHasExam: e.target.checked,
        });
      }
      else if (e.target.name === "inputHasLevel") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputHasLevel: e.target.checked,
        });
      }
      else if (e.target.name === "inputHasStage") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputHasStage: e.target.checked,
        });
      }
      else if (e.target.name === "inputHasBook") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputHasBook: e.target.checked,
        });
      }
      else if (e.target.name === "inputHasContent") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputHasContent: e.target.checked,
        });
      }
      else if (e.target.name === "inputHasRating") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputHasRating: e.target.checked,
        });
      }
      else if (e.target.name === "inputShowFront") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputShowFront: e.target.checked,
        });
      }
    };
    
    const handleDescTextChange = (text) => {
        setdisabledBtn(false)
        setForm({
        ...form,
        inputDescText: text,
        });
        setFormError({
            ...formErrors,
            inputDescTextErr:
                    text === `<p><br></p>`
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
      };
      
    const handleDetailsTextChange = (text) => {
        setdisabledBtn(false)
        setForm({
        ...form,
        inputDetailsText: text,
        });
        setFormError({
            ...formErrors,
            inputDetailsTextErr:
                    text === `<p><br></p>`
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
      };
    
    const validationForm = ()=>{
        setFormError({
        ...formErrors,
        inputTitleErr: form.inputTitle === "" ?"* هذا الحقل مطلوب" :null,
        inputDescTextErr:  form.inputDescText === ""? "* هذا الحقل مطلوب":null,
        inputVideoLinkErr:  form.inputVideoLink === ""? "* هذا الحقل مطلوب":null,
        inputPDFErr:  form.inputPDF === ""? "* هذا الحقل مطلوب":null,
        inputDetailsTextErr: form.inputDetailsText === "" ? "* هذا الحقل مطلوب":null,
        inputIntroImageErr: form.inputIntroImage === ""? "* هذا الحقل مطلوب":null,
        inputLogoImageErr: form.inputLogoImage === ""? "* هذا الحقل مطلوب":null,
        inputEnrollStatusErr: form.inputEnrollStatus === ""? "* هذا الحقل مطلوب":null,
        inputPriceErr: form.inputPrice === ""? "* هذا الحقل مطلوب":null,
        inputStartTypeErr: form.inputStartType === ""? "* هذا الحقل مطلوب":null,
        inputStartDateErr: form.inputStartDate === ""? "* هذا الحقل مطلوب":null,
        inputEndDateErr: form.inputEndDate === ""? "* هذا الحقل مطلوب":null,
        inputPresenterErr: form.inputPresenter === ""? "* هذا الحقل مطلوب":null,
        inputProgrammeScoreErr: form.inputProgrammeScore === ""? "* هذا الحقل مطلوب":null,
        inputPassScoreErr: form.inputPassScore === ""? "* هذا الحقل مطلوب":null,
        });
    }
  
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setdisabledBtn(true)
        if(form.inputTitle === "" || form.inputDescText === "" || form.inputVideoLink === "" || form.inputPDF === "" || form.inputDetailsText === "" || form.inputIntroImage === "" || form.inputLogoImage === "" || form.inputEnrollStatus === "" || (form.inputEnrollStatus === "paied" &&form.inputPrice === "") || form.inputStartType === "" || (form.inputStartDate === "Timebound" && form.inputStartDate === "") || (form.inputStartDate === "Timebound" && form.inputEndDate === "") || form.inputPresenter === "" || form.inputProgrammeScore === "" || form.inputPassScore === ""){
        validationForm()
        return
        }
        const formData = new FormData();
        formData.append('Title', form.inputTitle);
        formData.append('DescriptionText', form.inputDescText);
        formData.append('DescriptionVideo', form.inputVideoLink);
        formData.append('DescriptionPDF', form.inputPDF);
        formData.append('DetailsText', form.inputDetailsText);
        formData.append('IntroImage', form.inputIntroImage);
        formData.append('LogoImage', form.inputLogoImage);
        formData.append('EnrollStatus', form.inputEnrollStatus);
        formData.append('Price', form.inputPrice);
        formData.append('StartType', form.inputStartType);
        formData.append('StartDate', form.inputStartDate);
        formData.append('EndDate', form.inputEndDate);
        formData.append('Presenter', form.inputPresenter);
        formData.append('ProgrammeScore', form.inputProgrammeScore);
        formData.append('PassScore', form.inputPassScore);
        formData.append('MainProgramme', form.inputMainProgramme);
        formData.append('HasCertification', form.inputHasCertification);
        formData.append('HasExam', form.inputHasExam);
        formData.append('HasLevel', form.inputHasLevel);
        formData.append('HasStage', form.inputHasStage);
        formData.append('HasBook', form.inputHasBook);
        formData.append('HasContent', form.inputHasContent);
        formData.append('HasRating', form.inputHasRating);
        formData.append('SoftDelete', false);
        formData.append('ShowFront', form.inputShowFront);
        formData.append('Active', true);
        try{
            await axiosInstance.post("/admin/programmes",formData ,{
                headers: {
                authorization: auth.token,
                },
            });
            handleClearForm()
            toast.success('تم انشاء البرنامج بنجاح');
            history.push('/programmes');
        } catch (err) {
            console.log(" ----------> ",err)
            if(err.response.data === "PROGRAMME_EXIST"){
                toast.error("اسم البرنامج مستخدم بالفعل");
                setFormError({inputTitleErr: '* اسم البرنامج مستخدم بالفعل يرجى تغيير اسم البرنامج'})
            }
        }
    };

    const handleClearForm = ()=>{
        setForm({
            inputTitle: "",
            inputDescText: "",
            inputVideoLink: "",
            inputPDF: "",
            inputDetailsText: "",
            inputIntroImage: "",
            inputLogoImage: "",
            inputEnrollStatus: "",
            inputPrice: "",
            inputStartType: "",
            inputStartDate: "",
            inputEndDate: "",
            inputPresenter: "",
            inputProgrammeScore: "",
            inputPassScore: "",
            inputMainProgramme: false,
            inputHasCertification: false,
            inputHasExam: false,
            inputHasLevel: false,
            inputHasStage: false,
            inputHasBook: false,
            inputHasContent: false,
            inputHasRating: false,
            inputShowFront: false,
        });
        setFormError({
            inputTitleErr: null,
            inputDescTextErr: null,
            inputVideoLinkErr: null,
            inputPDFErr: null,
            inputDetailsTextErr: null,
            inputIntroImageErr: null,
            inputLogoImageErr: null,
            inputEnrollStatusErr: null,
            inputPriceErr: null,
            inputStartTypeErr: null,
            inputStartDateErr: null,
            inputEndDateErr: null,
            inputPresenterErr: null,
            inputProgrammeScoreErr: null,
            inputPassScoreErr: null
        });
      }
      const handleBack = ()=>{
        handleClearForm()
        history.push('/programmes');
      }

  return (
    <>
        {auth !== null && <section>
            <ToastContainer />
            <h1>
                إضافة برنامج جديد
            </h1>
            <Programme
                form={form}
                formErrors={formErrors}
                quill={quill}
                quillFormats={quillFormats}
                handelFormChange={handelFormChange}
                handleFormSubmit={handleFormSubmit}
                handleKeyPress={handleKeyPress}
                handleEnglishKeyPress={handleEnglishKeyPress}
                handleDescTextChange={handleDescTextChange}
                handleDetailsTextChange={handleDetailsTextChange}
                handleBack={handleBack}
                disabledBtn={disabledBtn}
                action={'addProgramme'}
            />
        </section>}
    </>   
  )
}
