import React from 'react';
import './Stage.scss';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import Select from 'react-select'

export default function Stage({
  form,
  formErrors,
  quill,
  quillFormats,
  programmesListOptions,
  levelsListOptions,
  handleProgrammeChange,
  handleLevelChange,
  handelFormChange,
  handleFormSubmit,
  handleDescTextChange,
  handleKeyPress,
  handleBack,
  disabledBtn,
  action
}) {
  
  return (
    <section className='stage w-50'>
      <form onSubmit={(e) => handleFormSubmit(e)}>

        <div className="mt-3 position-relative">
            <label htmlFor="inputProgrammeId" className="form-label secondary-color fs-6">البرنامج</label>
            <Select 
              className="selectProgramme" 
              options={programmesListOptions} 
              value={form.inputProgrammeId?programmesListOptions.find(e => e.value === form.inputProgrammeId):""}  
              onChange={handleProgrammeChange} 
              placeholder="اختر البرنامج" 
              name='inputProgrammeId'
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(76,125,120,.25)',
                  primary: 'rgba(76,125,120,.25)',
                },
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  boxShadow: state.isFocused ?'0 0 0 0.25rem rgba(76,125,120,.25)':'',
                  borderColor: state.isFocused?'#4c7d78': "hsl(0, 0%, 80%)",
                }),
                placeholder: (styles) => ({ ...styles, color:'#4c7d78' }),
                option: (styles) => ({ ...styles, color:'#4c7d78' }),
                singleValue: (styles, { data }) => ({ ...styles, color:'#4c7d78'})
              }}
            />
            <div className="pt-1 text-danger" id='inputprogrammeId'>
                <small>
                    {formErrors.inputProgrammeIdErr}
                </small>
            </div>
        </div>

        {levelsListOptions.length === 0 ?
            <></>
        :
        <div className="mt-3 position-relative">
                <label htmlFor="inputLevelId" className="form-label secondary-color fs-6">المستوى</label>
                <Select 
                className="selectLevel" 
                options={levelsListOptions} 
                value={form.inputLevelId?levelsListOptions.find(e => e.value === form.inputLevelId):""}  
                onChange={handleLevelChange} 
                placeholder="اختر المستوى" 
                name='inputLevelId'
                theme={(theme) => ({
                    ...theme,
                    // borderRadius: 0,
                    colors: {
                    ...theme.colors,
                    primary25: 'rgba(76,125,120,.25)',
                    primary: 'rgba(76,125,120,.25)',
                    },
                })}
                styles={{
                    control: (baseStyles, state) => ({
                    ...baseStyles,
                    boxShadow: state.isFocused ?'0 0 0 0.25rem rgba(76,125,120,.25)':'',
                    borderColor: state.isFocused?'#4c7d78': "hsl(0, 0%, 80%)",
                    }),
                    placeholder: (styles) => ({ ...styles, color:'#4c7d78' }),
                    option: (styles) => ({ ...styles, color:'#4c7d78' }),
                    singleValue: (styles, { data }) => ({ ...styles, color:'#4c7d78'})
                }}
                />
                <div className="pt-1 text-danger" id='inputlevelId'>
                    <small>
                        {formErrors.inputLevelIdErr}
                    </small>
                </div>
            </div>
        }
        <div className="mt-3 position-relative">
            <label htmlFor="inputTitle" className="form-label secondary-color fs-6">* عنوان المرحلة</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-book" viewBox="0 0 16 16">
                <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
              </svg>
            </span>
            <input type="text" className={`form-control px-5 secondary-color ${formErrors.inputTitleErr?"inputErr":""}`} name="inputTitle" aria-describedby="inputtitle" value={form.inputTitle} onChange={(e) => handelFormChange(e)}  onKeyDown={(e) =>handleKeyPress(e)}/>
            <div className="pt-1 text-danger" id='inputtitle'>
                <small>
                    {formErrors.inputTitleErr}
                </small>
            </div>
        </div>

        <div className="my-3 position-relative">
            <label htmlFor="inputDescText" className="form-label secondary-color fs-6">* وصف المرحلة</label>
            <ReactQuill
            theme="snow"
            modules={quill}
            formats={quillFormats}
            placeholder="ادخل وصف المرحلة ...."
            onChange={(e) => handleDescTextChange(e)}
            // style={{ height: "220px"}}
            className={`secondary-color ${formErrors.inputDescTextErr?"inputErr":""}`}
            name="inputDescText" 
            aria-describedby="inputdescText" 
            value={form.inputDescText}
            onKeyDown={(e) =>handleKeyPress(e)}
            ></ReactQuill>
            <div className="pt-1 text-danger" id='inputdescText'>
                <small>
                    {formErrors.inputDescTextErr}
                </small>
            </div>
        </div>

        <div className="mt-3 position-relative">
          <label htmlFor="inputStartDate" className="form-label secondary-color fs-6">تاريخ بدأ المرحلة</label>
          <span className='position-absolute user-icon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-plus" viewBox="0 0 16 16">
              <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7"/>
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
            </svg>
          </span>
          <input type="date" className={`form-control secondary-color ${formErrors.inputStartDateErr?"inputErr":""}`} id="inputStartDate" name="inputStartDate" aria-describedby="inputstartdate" value={form.inputStartDate} onChange={(e) => handelFormChange(e)} min={new Date().toISOString().split("T")[0]}/>
          <div className="pt-1 text-danger" id='inputstartdate'>
              <small>
                  {formErrors.inputStartDateErr}
              </small>
          </div>
        </div>

        <div className="mt-3 position-relative">
          <label htmlFor="inputEndDate" className="form-label secondary-color fs-6">تاريخ انتهاء المرحلة</label>
          <span className='position-absolute user-icon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-plus" viewBox="0 0 16 16">
              <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7"/>
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
            </svg>
          </span>
          <input type="date" className={`form-control secondary-color ${formErrors.inputEndDateErr?"inputErr":""}`} id="inputEndDate" name="inputEndDate" aria-describedby="inputenddate" value={form.inputEndDate} onChange={(e) => handelFormChange(e)} min={form.inputStartDate?form.inputStartDate:new Date().toISOString().split("T")[0]}/>
          <div className="pt-1 text-danger" id='inputenddate'>
              <small>
                  {formErrors.inputEndDateErr}
              </small>
          </div>
        </div>

        <div className="mt-3 position-relative">
            <label htmlFor="inputStageScore" className="form-label secondary-color fs-6">* مجموع الدرجات للمرحلة</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-binary" viewBox="0 0 16 16">
                <path d="M5.526 13.09c.976 0 1.524-.79 1.524-2.205 0-1.412-.548-2.203-1.524-2.203-.978 0-1.526.79-1.526 2.203 0 1.415.548 2.206 1.526 2.206zm-.832-2.205c0-1.05.29-1.612.832-1.612.358 0 .607.247.733.721L4.7 11.137a7 7 0 0 1-.006-.252m.832 1.614c-.36 0-.606-.246-.732-.718l1.556-1.145q.005.12.005.249c0 1.052-.29 1.614-.829 1.614m5.329.501v-.595H9.73V8.772h-.69l-1.19.786v.688L8.986 9.5h.05v2.906h-1.18V13h3z"/>
                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"/>
              </svg>
            </span>
            <input type="number" className={`form-control px-5 secondary-color ${formErrors.inputStageScoreErr?"inputErr":""}`} name="inputStageScore" aria-describedby="inputStageScore" value={form.inputStageScore} onChange={(e) => handelFormChange(e)} min={0}/>
            <div className="pt-1 text-danger" id='inputstagescore'>
                <small>
                    {formErrors.inputStageScoreErr}
                </small>
            </div>
          </div>

        <div className="mt-3 position-relative">
          <label htmlFor="inputPassScore" className="form-label secondary-color fs-6">* درجة النجاح للمرحلة</label>
          <span className='position-absolute user-icon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-binary" viewBox="0 0 16 16">
              <path d="M5.526 13.09c.976 0 1.524-.79 1.524-2.205 0-1.412-.548-2.203-1.524-2.203-.978 0-1.526.79-1.526 2.203 0 1.415.548 2.206 1.526 2.206zm-.832-2.205c0-1.05.29-1.612.832-1.612.358 0 .607.247.733.721L4.7 11.137a7 7 0 0 1-.006-.252m.832 1.614c-.36 0-.606-.246-.732-.718l1.556-1.145q.005.12.005.249c0 1.052-.29 1.614-.829 1.614m5.329.501v-.595H9.73V8.772h-.69l-1.19.786v.688L8.986 9.5h.05v2.906h-1.18V13h3z"/>
              <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"/>
            </svg>
          </span>
          <input type="number" className={`form-control px-5 secondary-color ${formErrors.inputPassScoreErr?"inputErr":""}`} name="inputPassScore" aria-describedby="inputpassscore" value={form.inputPassScore} onChange={(e) => handelFormChange(e)} min={0}/>
          <div className="pt-1 text-danger" id='inputpassscore'>
              <small>
                  {formErrors.inputPassScoreErr}
              </small>
          </div>
        </div>

        <div className="mt-3 position-relative">
          <label htmlFor="inputHasExam" className="form-label secondary-color fs-6">امتحان المرحلة</label>
          <div className='d-flex px-5'>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-text" viewBox="0 0 16 16">
                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"/>
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
              </svg>
            </span>
            <input type="checkbox" name="inputHasExam" className='me-3 mt-2' checked={form.inputHasExam}  onChange={(e) => handelFormChange(e)}/>
            <label htmlFor="inputHasExam" className="secondary-color fs-6 me-2 mt-2">نعم للمرحلة امتحان للنجاح</label>
          </div>
        </div>

        <div className="mt-3 position-relative">
          <label htmlFor="inputHasRating" className="form-label secondary-color fs-6">تقييم المرحلة</label>
          <div className='d-flex px-5'>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
              </svg>
            </span>
            <input type="checkbox" name="inputHasRating" className='me-3 mt-2' checked={form.inputHasRating}  onChange={(e) => handelFormChange(e)}/>
            <label htmlFor="inputHasRating" className="secondary-color fs-6 me-2 mt-2">نعم للمرحلة تقييم</label>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          <button 
            type="submit" 
            className="btn rounded-pill text-white px-5 mx-3"
            disabled={
              formErrors.inputProgrammeIdErr ||
              formErrors.inputLevelIdErr ||
              formErrors.inputTitleErr ||
              formErrors.inputDescTextErr ||
              formErrors.inputStartDateErr ||
              formErrors.inputEndDateErr ||
              formErrors.inputLevelScoreErr ||
              formErrors.inputPassScoreErr ||
              disabledBtn
            }
          >{action === "addStage" ? "إنشاء مرحلة جديد":"تعديل المرحلة"}</button>
          <button 
              type="button" 
              className="btn rounded-pill text-white px-5 mx-3"
              onClick={handleBack}
            >رجوع</button>
        </div>
      </form>
  </section>
  )
}

