let useState ;
if(window.localStorage.getItem("auth")){
  useState=JSON.parse(window.localStorage.getItem("auth"))
}else{
  useState=null
}
export default function auth(state = useState,action) {
    switch (action.type) {
      case "LOGGED_IN_ADMIN":
        return { ...state, ...action.payload};
      case "LOGOUT":
        return action.payload;
      default:
        return state;
    }
  }