import React, { useState, useEffect } from 'react';
import Stage from '../../Components/Stage/Stage'
import Loading from '../../Components/Loading/Loading'

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { EditStages } from "../../store/actions/stages";
import { axiosInstance } from "../../network/axios";

export default function EditBook() {
    const history = useHistory();
    const params = useParams();
    let auth  = useSelector(state => state.auth);
    let programmesStageList = useSelector((state) => state.programmes.programmesStageList);
    let stagesList = useSelector((state) => state.stages.stagesList.rows);

    const [levelsListOptions,setLevelsListOptions]=useState([])
    const numberRegex = /^\d*\.?\d{0,2}$/;
    const namePattern=new RegExp("^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]+( [\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]+)*$")
    const [stage, setStage] = useState();
    const [loading, setLoading] = useState(false);
    const [disabledBtn, setdisabledBtn] = useState(true);

    const quill = {
        toolbar: [
            // [{ 'direction': 'rtl' }, { 'direction': 'ltr' }],
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
        //   ["link", "image"],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    const quillFormats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
    ];

    const programmesListOptions = programmesStageList.map((programme)=>({
        value: programme.ID, label: programme.Title 
    }))

    const [form, setForm] = useState({
        inputProgrammeId: null,
        inputLevelId: null,
        inputTitle: "",
        inputDescText: "",
        inputStartDate: "",
        inputEndDate: "",
        inputStageScore: "",
        inputPassScore: "",
        inputHasExam: false,
        inputHasRating: false,
    });

    const [formErrors, setFormError] = useState({
        inputProgrammeIdErr:null,
        inputLevelIdErr:null,
        inputTitleErr: null,
        inputDescTextErr: null,
        inputStartDateErr: null,
        inputEndDateErr: null,
        inputStageScoreErr: null,
        inputPassScoreErr: null
    });

    useEffect(()=>{
        if(auth === null){
            history.push('/');
        }else{
            handleGetStage()
        }
        return () => {
            handleClearForm()
          };
    },[auth, history,params.id])

    const handleGetStage = async()=>{
        setLoading(true)
      if(stagesList.length === 0){
        history.push('/stages');
      }else{
        const findStage = stagesList.find((stage)=>stage.ID === Number(params.id))
        if(findStage){
          setStage(findStage)
          setForm({
            inputProgrammeId: findStage.Programme_Id,
            inputLevelId: findStage.Level_Id,
            inputTitle: findStage.Title,
            inputDescText: findStage.DescriptionText,
            inputStartDate: findStage.StartDate,
            inputEndDate: findStage.EndDate,
            inputStageScore: findStage.StageScore,
            inputPassScore: findStage.PassScore,
            inputHasExam: findStage.HasExam,
            inputHasRating: findStage.HasRating,
        });
        handleGetLevelsByProgramme(findStage.Programme_Id)
        }else{
          history.push('/stages');
        }
      }
      setLoading(false)
    }

    const handleKeyPress = (event) => {
        if (
            !(
                /^[\u0600-\u06FF\s]$/.test(event.key) || 
                event.key === 'Backspace' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    };

    const handleEnglishKeyPress = (event) => {
        if (
            !(
                /^[a-zA-Z0-9:/?.#-_]*$/.test(event.key) || 
                event.key === 'Backspace' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    };

    const handelFormChange = (e) => {
      if (e.target.name === "inputTitle") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputTitle: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputTitleErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : /^\s|\s$/.test(e.target.value)
                    ? "* يجب ان لا يبدأ او ينتهى الاسم بمسافة"
                    : /\s{2,}/.test(e.target.value)
                    ? "* يجب ان لا يحتوى الاسم على مسافتين"
                    : !namePattern.test(e.target.value)
                    ? "* يجب كتابة الاسم باللغة العربية ولا يحتوى على اى رموز او ارقام"
                    : null,
        });
      } 
      else if (e.target.name === "inputStartDate") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputStartDate: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputStartDateErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : null,
          });
      }
      else if (e.target.name === "inputEndDate") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputEndDate: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputEndDateErr:
                      new Date(e.target.value) < new Date(form.inputStartDate)
                      ? "* يجب ان يكون تاريخ الانتهاء بعد تاريخ البدء"
                      : null,
          });
      }
      else if (e.target.name === "inputStageScore") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputStageScore: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputStageScoreErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : !numberRegex.test(e.target.value)
                    ? "* يجب ان تكون القيمة رقم صحيح"
                    : null,
        });
      }
      else if (e.target.name === "inputPassScore") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputPassScore: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputPassScoreErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : !numberRegex.test(e.target.value)
                    ? "* يجب ان تكون القيمة رقم صحيح"
                    : null,
        });
      }
      else if (e.target.name === "inputHasExam") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputHasExam: e.target.checked,
        });
      }
      else if (e.target.name === "inputHasRating") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputHasRating: e.target.checked,
        });
      }
    };
    
    const handleDescTextChange = (text) => {
        setdisabledBtn(false)
        setForm({
        ...form,
        inputDescText: text,
        });
        setFormError({
            ...formErrors,
            inputDescTextErr:
                    text === `<p><br></p>`
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
      };
    
      const handleProgrammeChange =(programme)=>{
        setdisabledBtn(false)
        setForm({
        ...form,
        inputProgrammeId: programme.value,
        inputLevelId: 0
        });
        setFormError({
            ...formErrors,
            inputProgrammeIdErr:
                    programme.value === undefined
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
        handleGetLevelsByProgramme(programme.value)
    }

    const handleGetLevelsByProgramme = async(programme)=>{
        try{
            let res = await axiosInstance.get(`/admin/programmelevelsid/${programme}`, {
                headers: {
                authorization: auth.token,
                },
            })
            if(res.data){
                setLevelsListOptions(res.data.map((level)=>({
                    value: level.ID, label: level.Title 
                })))
            }
        }catch(err){}
    }

      const handleLevelChange =(level)=>{
        setdisabledBtn(false)
        setForm({
        ...form,
        inputLevelId: level.value,
        });
        setFormError({
            ...formErrors,
            inputLevelIdErr:
                    level.value === undefined
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
    }
    
    const validationForm = ()=>{
        setFormError({
        ...formErrors,
        inputProgrammeIdErr: form.inputProgrammeId === "" ?"* هذا الحقل مطلوب" :null,
        inputLevelIdErr: form.inputLevelId === "" ?"* هذا الحقل مطلوب" :null,
        inputTitleErr: form.inputTitle === "" ?"* هذا الحقل مطلوب" :null,
        inputDescTextErr:  form.inputDescText === ""? "* هذا الحقل مطلوب":null,
        inputStartDateErr: form.inputStartDate === ""? "* هذا الحقل مطلوب":null,
        inputEndDateErr: form.inputEndDate === ""? "* هذا الحقل مطلوب":null,
        inputStageScoreErr: form.inputStageScore === ""? "* هذا الحقل مطلوب":null,
        inputPassScoreErr: form.inputPassScore === ""? "* هذا الحقل مطلوب":null,
        });
    }
  
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setdisabledBtn(true)
        if(form.inputProgrammeId === ""|| form.inputLevelId === ""|| form.inputTitle === "" || form.inputDescText === "" || form.inputStartDate === "" || form.inputEndDate === "" || form.inputStageScore === "" || form.inputPassScore === ""){
        validationForm()
        return
        }

        try{
            await EditStages(params.id,{
                Programme_Id:form.inputProgrammeId,
                Level_Id:form.inputLevelId,
                Title: form.inputTitle,
                DescriptionText: form.inputDescText,
                StartDate: form.inputStartDate,
                EndDate: form.inputEndDate,
                StageScore: form.inputStageScore,
                PassScore: form.inputPassScore,
                HasExam: form.inputHasExam,
                HasRating: form.inputHasRating,
                SoftDelete: false,
                Active: true
            },auth.token)
            handleClearForm()
            toast.success('تم تعديل المرحلة بنجاح');
            history.push('/stages');
        } catch (err) {
            console.log(" ----------> ",err)
            if(err.response.data === "STAGE_EXIST"){
                toast.error("اسم المرحلة مستخدم بالفعل");
                setFormError({inputTitleErr: '* اسم المرحلة مستخدم بالفعل يرجى تغيير اسم المرحلة'})
            }
        }
    };

    const handleClearForm = ()=>{
        setStage(null)
        setForm({
            inputTitle: "",
            inputDescText: "",
            inputStartDate: "",
            inputEndDate: "",
            inputStageScore: "",
            inputPassScore: "",
            inputHasExam: false,
            inputHasRating: false,
        });
        setFormError({
            inputTitleErr: null,
            inputDescTextErr: null,
            inputStartDateErr: null,
            inputEndDateErr: null,
            inputStageScoreErr: null,
            inputPassScoreErr: null
        });
      }
      const handleBack = ()=>{
        handleClearForm()
        history.push('/stages');
      }

  return (
    <>
        {auth !== null && <section>
            <ToastContainer />
            <h1>
                تعديل المرحلة
            </h1>
            {loading || !stage?
                <Loading />
            :
                <Stage
                    form={form}
                    formErrors={formErrors}
                    quill={quill}
                    quillFormats={quillFormats}
                    programmesListOptions={programmesListOptions}
                    levelsListOptions={levelsListOptions}
                    handleProgrammeChange={handleProgrammeChange}
                    handleLevelChange={handleLevelChange}
                    handelFormChange={handelFormChange}
                    handleFormSubmit={handleFormSubmit}
                    handleKeyPress={handleKeyPress}
                    handleEnglishKeyPress={handleEnglishKeyPress}
                    handleDescTextChange={handleDescTextChange}
                    handleBack={handleBack}
                    disabledBtn={disabledBtn}
                    action={'editStage'}
                />
            }
        </section>}
    </>   
  )
}
