import { axiosInstance } from "../../network/axios";

export const getAccepts =  (token,paginationModel) => async (dispatch) => {
    await axiosInstance.get(`/admin/usersprogrammes?page=${paginationModel.page}&limit=${paginationModel.pageSize}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_ACCCEPTS_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const getProgrammeAccepts =  (token,programme,paginationModel) => async (dispatch) => {
    await axiosInstance.get(`/admin/usersprogrammes/${programme}&page=${paginationModel.page}&limit=${paginationModel.pageSize}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_ACCCEPTS_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}