const INITIAL_STATE = {
    usersList : {
      rows:[],
      totalCount:0
    }
}

export default function users(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_USERS_LIST":
        return {
            ...state,
            usersList : action.payload 
        };
      default:
        return state;
    }
  }