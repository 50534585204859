import { axiosInstance } from "../../network/axios";

export const getStages =  (token,paginationModel) => async (dispatch) => {
    await axiosInstance.get(`/admin/stages?page=${paginationModel.page}&limit=${paginationModel.pageSize}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_STAGES_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}


export const getProgrammeStages =  (programme,token,paginationModel) => async (dispatch) => {
    await axiosInstance.get(`/admin/programmestages/${programme}&page=${paginationModel.page}&limit=${paginationModel.pageSize}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_STAGES_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const getLevelStages =  (level,token,paginationModel) => async (dispatch) => {
    await axiosInstance.get(`/admin/levelstages/${level}&page=${paginationModel.page}&limit=${paginationModel.pageSize}`, {
        headers: {
        authorization: token,
        },
    }).then((res) =>
        dispatch({
            type: "GET_STAGES_LIST",
            payload: res.data,
        })
    ).catch((err) => console.log(err))
}

export const deleteStage =  async(id,token) => {
    await axiosInstance.delete(`/admin/stages/${id}`, {
        headers: {
        authorization: token,
        },
    })
}

export const AddStages =  async (stage , token) =>{
    await axiosInstance.post("/admin/stages",stage ,{
        headers: {
        authorization: token,
        },
    });
}

export const EditStages =  async (id , stage , token) =>{
    await axiosInstance.patch(`/admin/stages/${id}`,stage ,{
        headers: {
        authorization: token,
        },
    });
}